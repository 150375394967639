import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2'
import * as VueGoogleMaps from 'vue2-google-maps'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import VueCookies from 'vue-cookies'
import VueCarousel from 'vue-carousel'
import SocialSharing from "vue-social-sharing";
import vClickOutside from 'v-click-outside'
 
Vue.use(vClickOutside)
Vue.use(SocialSharing);
const moment = require("moment");
require("moment/locale/es");
Vue.use(require("vue-moment"), {
  moment
});
Vue.use(VueSweetalert2)
Vue.use(VueCarousel)
Vue.use(VueCookies)
Vue.use(Croppa)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCuJd1VMfmKwi1bW7yjCXysZ1gND1QqTE8',
    libraries: 'places'
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
