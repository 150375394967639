<template lang="pug">
  #Ingreso
    Header
    div(style='background-color: rgb(250, 250, 250);height: 100px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs10)
        v-layout(wrap)
          v-flex(xs6)
            span(style='font-weight: 500;opacity: 0.8;') Perfil
          v-flex(xs6 style='display: flex;justify-content: flex-end;')
            a(style='font-weight: bold;color:black;text-decoration: initial;' href='/') CASA
            span(style='padding-left:10px;') /
            span(style='padding-left:10px;font-weight: bold;opacity: 0.6;text-transform: uppercase;') PERFIL
    div(style='height: auto;display: flex;justify-content: center;align-items: center;')
      v-flex(xs10)
        v-layout(wrap style='margin-top:70px;')
          v-flex(xs12 style='margin-bottom:60px;padding:15px')
            h2(style='margin-bottom:10px;') PERFIL
            v-card(style='border:1px solid #eeeeee;padding:30px;height: 90%;').elevation-0
              v-layout(wrap)
                v-flex(xs12 sm6 style='padding:5px;')
                  span(style='font-weight:500;') Nombre
                  v-text-field(outlined v-model='form.nombre' placeholder='Nombre' color='#878787')
                v-flex(xs12 sm6 style='padding:5px;')
                  span(style='font-weight:500;') Rif
                  v-text-field(outlined v-model='form.rif' placeholder='Rif' color='#878787')
                v-flex(xs12 style='padding:5px;')
                  span(style='font-weight:500;') Dirección
                  v-textarea(outlined v-model='form.direccion' placeholder='Dirección' color='#878787')
                v-flex(xs12 sm6 style='padding:5px;')
                  span(style='font-weight:500;') Correo electrónico
                  v-text-field(outlined v-model='form.email' disabled placeholder='Correo electrónico' color='#878787')
                v-flex(xs12 sm6 style='padding:5px;')
                  span(style='font-weight:500;') Contraseña
                  v-text-field(outlined :append-icon="show1 ? 'visibility' : 'visibility_off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" v-model='form.password' placeholder='Contraseña' color='#bfdeff')
                v-flex(xs12)
                  v-btn(style='background:#f2eddb;color:black;border-radius:0px;' @click='save()').elevation-0 Guardar
    Footer
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Api from '@/services/methods'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { mapState, mapActions } from 'vuex'
import { serverImg } from '@/services/environment'
export default {
  name: 'Perfil',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      show1: false,
      form: {
        nombre: '',
        rif: '',
        direccion: '',
        email: '',
        password: ''
      },
      pantalla: screen.width,
      ruta: serverImg
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    save () {
      if (this.form.nombre && this.form.rif && this.form.direccion && this.form.email && this.form.password) {
        this.form.email = this.form.email.toLowerCase()
        Api.put('clientes/web', this.form)
          .then(response => {
            if (response.data.message) {
              this.showAlert3(response.data.message)
            } else {
              this.$store.dispatch('modificarperfil', response.data)
              this.showAlertRegistro()
            }
          })
          .catch(error => {
            console.log(error)
            this.showAlert2()
          })
      } else {
        this.showAlert()
      }
    },
    showAlertRegistro () {
      Swal.fire({
        title: '¡Felicidades!',
        text: 'Su modificación se realizó con éxito.',
        icon: 'success'
      }).then((result) => {
        if (result.value) {
          window.location.href = '/perfil'
        }
      })
    },
    showAlert3 (item) {
      Swal.fire(
        'Error inesperado',
        item,
        'error'
      )
    },
    showAlert2 () {
      Swal.fire(
        'Error inesperado',
        'Ha ocurrido un error, por favor verifique la información.',
        'error'
      )
    },
    showAlert () {
      Swal.fire({
        title: 'Error encontrado.',
        text: 'Estimado cliente, para completar la modificación debe ingresar todos los campos solicitados.',
        icon: 'error'
      })
    },
    initialize () {
      if (this.$store.state.fercoWeb) {
        Object.keys(this.form).map(key => {
          if (this.$store.state.fercoWeb[key]) this.form[key] = this.$store.state.fercoWeb[key]
        })
        this.form._id = this.$store.state.fercoWeb._id
      }
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/ingreso.scss";
</style>
