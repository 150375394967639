import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Detalle from '../views/Detalle.vue'
import Carrito from '../views/Carrito.vue'
import Ingreso from '../views/Ingreso.vue'
import Registrarse from '../views/Registrarse.vue'
import Acerca from '../views/Acerca.vue'
import Contacto from '../views/Contacto.vue'
import Buscar from '../views/Buscar.vue'
import Perfil from '../views/Perfil.vue'
import Productos from '../views/Productos.vue'
import Historial from '../views/Historial.vue'
import Facturas from '../views/Facturas.vue'
import Recuperar from '../views/Recuperar.vue'
import Restablecer from '../views/Restablecer.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/carrito',
    name: 'Carrito',
    component: Carrito
  },
  {
    path: '/acerca',
    name: 'Acerca',
    component: Acerca
  },
  {
    path: '/restablecer/:id',
    name: 'Restablecer',
    component: Restablecer
  },
  {
    path: '/historial',
    name: 'Historial',
    component: Historial
  },
  {
    path: '/facturas',
    name: 'Facturas',
    component: Facturas
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: Perfil
  },
  {
    path: '/ingreso',
    name: 'Ingreso',
    component: Ingreso
  },
  {
    path: '/productos',
    name: 'Productos',
    component: Productos
  },
  {
    path: '/buscar',
    name: 'Buscar',
    component: Buscar
  },
  {
    path: '/recuperar',
    name: 'Recuperar',
    component: Recuperar
  },
  {
    path: '/registrarse',
    name: 'Registrarse',
    component: Registrarse
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: Contacto
  },
  {
    path: '/detalle/:id',
    name: 'Detalle',
    component: Detalle
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
