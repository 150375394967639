<template lang="pug">
  #Ingreso
    Header
    div(style='background-color: gainsboro;height: 60px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 style='display: flex;justify-content: flex-start;align-items: center;')
            a(style='font:size:14px;font-weight: bold;color: #FF6200;text-decoration: initial;' href='/') Inicio
            span(style='padding-left:10px;') /
            span(style='font:size:14px;padding-left:10px;font-weight: bold;') Crear cuenta
    div(style='height: auto;display: flex;justify-content: center;align-items: center;')
      v-flex(xs10)
        v-layout(wrap style='margin-top:70px;')
            v-flex(xs12 sm12 style='margin-bottom:60px;padding:15px')
                h2(style='margin-bottom:10px;') CREAR UNA CUENTA
                v-card(style='border:1px solid #eeeeee;padding:30px;height: 100%;').elevation-0
                    v-layout(wrap)
                        v-flex(xs12 sm6 style='padding:5px;')
                            span(style='font-weight:500;') Nombre
                            v-text-field(outlined v-model='form.nombre' placeholder='Nombre' color='#878787')
                        v-flex(xs12 sm6 style='padding:5px;')
                            span(style='font-weight:500;') Rif
                            v-text-field(outlined v-model='form.rif' placeholder='Rif' color='#878787')
                        v-flex(xs12 style='padding:5px;')
                            span(style='font-weight:500;') Dirección
                            v-textarea(outlined v-model='form.direccion' placeholder='Dirección' color='#878787')
                        v-flex(xs12 sm6 style='padding:5px;')
                            span(style='font-weight:500;') Correo electrónico
                            v-text-field(outlined v-model='form.email' placeholder='Correo electrónico' color='#878787')
                        v-flex(xs12 sm6 style='padding:5px;')
                            span(style='font-weight:500;') Contraseña
                            v-text-field(outlined v-model='form.password' type='password' placeholder='Contraseña' color='#878787')
                        v-flex(xs12 sm6 style='padding:5px;')
                            span(style='font-weight:500;') Copia del Rif
                            div(style='display:flex;justify-content:center;')
                              img(:src='form.docRif.imageUrl', height='200', width='auto', v-if='form.docRif.imageUrl && !pdfRif' style='margin-bottom:20px;')
                              img(src='../assets/pdf.png', height='60', width='80'  v-if='form.docRif.imageUrl && pdfRif')
                            v-text-field(outlined label='Seleccione imagen', @click='pickFile', v-model='form.docRif.imageName', prepend-inner-icon='attach_file' color='#878787')
                            input(
                              type="file"
                              style="display: none;"
                              ref="image"
                              accept="image/*,application/pdf"
                              @change="onFilePicked")
                        v-flex(xs12 sm6 style='padding:5px;')
                            span(style='font-weight:500;') Copia del registro mercantil
                            div(style='display:flex;justify-content:center;')
                              img(:src='form.docMercan.imageUrl', height='200', width='auto', v-if='form.docMercan.imageUrl && !pdfMer' style='margin-bottom:20px;')
                              img(src='../assets/pdf.png', height='60', width='80'  v-if='form.docMercan.imageUrl && pdfMer')
                            v-text-field(outlined label='Seleccione imagen', @click='pickFileMer', v-model='form.docMercan.imageName', prepend-inner-icon='attach_file' color='#878787')
                            input(
                              type="file"
                              style="display: none;"
                              ref="imageMer"
                              accept="image/*,application/pdf"
                              @change="onFilePickedMer")
                        v-flex(xs12 sm6 style='padding:5px;')
                            span(style='font-weight:500;') Copia de cédula de representante legal
                            div(style='display:flex;justify-content:center;')
                              img(:src='form.docCI.imageUrl', height='200', width='auto', v-if='form.docCI.imageUrl && !pdfCI' style='margin-bottom:20px;')
                              img(src='../assets/pdf.png', height='60', width='80'  v-if='form.docCI.imageUrl && pdfCI')
                            v-text-field(outlined label='Seleccione imagen', @click='pickFileCI', v-model='form.docCI.imageName', prepend-inner-icon='attach_file' color='#878787')
                            input(
                              type="file"
                              style="display: none;"
                              ref="imageCI"
                              accept="image/*,application/pdf"
                              @change="onFilePickedCI")
                        v-flex(xs12 sm6 style='padding:5px;')
                            span(style='font-weight:500;') Referencia bancaria
                            div(style='display:flex;justify-content:center;')
                              img(:src='form.docRef.imageUrl', height='200', width='auto', v-if='form.docRef.imageUrl && !pdfRef' style='margin-bottom:20px;')
                              img(src='../assets/pdf.png', height='60', width='80'  v-if='form.docRef.imageUrl && pdfRef')
                            v-text-field(outlined label='Seleccione imagen', @click='pickFileRef', v-model='form.docRef.imageName', prepend-inner-icon='attach_file' color='#878787')
                            input(
                              type="file"
                              style="display: none;"
                              ref="imageRef"
                              accept="image/*,application/pdf"
                              @change="onFilePickedRef")
                        v-flex(xs12 v-if='!loading')
                            v-btn(style='background:#ff5700;color:white;border-radius:0px;' @click='registrarse()').elevation-0 Crear
                        v-flex(xs12 v-if='loading')
                          v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                            v-progress-circular(indeterminate='', color='#878787')
    Footer
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Api from '@/services/methods'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { mapState, mapActions } from 'vuex'
import { serverImg } from '@/services/environment'
export default {
  name: 'Registrarse',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      loading: false,
      pdfRif: false,
      pdfMer: false,
      pdfCI: false,
      pdfRef: false,
      form: {
        nombre: '',
        rif: '',
        estatus: 'Pendiente',
        direccion: '',
        email: '',
        password: '',
        docRif: {
          imageName: '',
          imageUrl: '',
          imageFile: ''
        },
        docMercan: {
          imageName: '',
          imageUrl: '',
          imageFile: ''
        },
        docCI: {
          imageName: '',
          imageUrl: '',
          imageFile: ''
        },
        docRef: {
          imageName: '',
          imageUrl: '',
          imageFile: ''
        }
      },
      pantalla: screen.width,
      ruta: serverImg
    }
  },
  created () {
    document.documentElement.scrollTop = 0
    this.initialize()
  },
  methods: {
    registrarse () {
      if (this.form.nombre && this.form.docMercan.imageFile && this.form.docCI.imageFile && this.form.docRef.imageFile && this.form.docRif.imageFile && this.form.direccion && this.form.rif && this.form.email && this.form.password) {
        this.form.email = this.form.email.toLowerCase()
        this.loading = true
        const data = new FormData()
        Object.keys(this.form).map(key => {
          if (Array.isArray(this.form[key])) {
            this.form[key].forEach(val => {
              if (typeof val === 'object' && val !== null) {
                return data.append(`${key}[]`, JSON.stringify(val))
              }
              return data.append(`${key}[]`, val)
            })
          } else if (
            typeof this.form[key] === 'object' &&
            this.form[key] !== null
          ) {
            return data.append(key, JSON.stringify(this.form[key]))
          } else {
            return data.append(key, this.form[key])
          }
        })
        if (this.form.docRif.imageFile) {
          data.append('rif', this.form.docRif.imageFile)
        }
        if (this.form.docMercan.imageFile) {
          data.append('mercantil', this.form.docMercan.imageFile)
        }
        if (this.form.docCI.imageFile) {
          data.append('cedula', this.form.docCI.imageFile)
        }
        if (this.form.docRef.imageFile) {
          data.append('referencia', this.form.docRef.imageFile)
        }
        Api.post('clientesWeb/', data)
          .then(response => {
            this.loading = false
            if (response.data.message) {
              this.showAlert3(response.data.message)
            } else {
              this.showAlertRegistro()
            }
          })
          .catch(error => {
            this.loading = false
            console.log(error)
            this.showAlert2()
          })
      } else {
        this.showAlert()
      }
    },
    pickFile () {
      this.$refs.image.click()
    },
    onFilePicked (e) {
      this.pdfRif = false
      const files = e.target.files
      if (files[0] !== undefined) {
        let peso = false
        for (let j = 0; j < files.length; j++) {
          if (files[j].size > 4194304) {
            peso = true
          }
        }
        if (peso === false) {
          if (files[0].type === 'application/pdf') {
            this.pdfRif = true
          }
          this.form.docRif.imageName = files[0].name
          if (this.form.docRif.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.form.docRif.imageUrl = fr.result
            this.form.docRif.imageFile = files[0] // this is an image file that can be sent to server...
          })
        } else {
          this.$swal.fire(
            'Oops...',
            'Error encontrado, la imagen debe pesar menos de 4MB.',
            'error'
          )
          this.form.docRif = []
        }
      } else {
        this.form.docRif.imageName = ''
        this.form.docRif.imageFile = ''
        this.form.docRif.imageUrl = ''
      }
    },
    pickFileMer () {
      this.$refs.imageMer.click()
    },
    onFilePickedMer (e) {
      this.pdfMer = false
      const files = e.target.files
      if (files[0] !== undefined) {
        let peso = false
        for (let j = 0; j < files.length; j++) {
          if (files[j].size > 4194304) {
            peso = true
          }
        }
        if (peso === false) {
          if (files[0].type === 'application/pdf') {
            this.pdfMer = true
          }
          this.form.docMercan.imageName = files[0].name
          if (this.form.docMercan.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.form.docMercan.imageUrl = fr.result
            this.form.docMercan.imageFile = files[0] // this is an image file that can be sent to server...
          })
        } else {
          this.$swal.fire(
            'Oops...',
            'Error encontrado, la imagen debe pesar menos de 4MB.',
            'error'
          )
          this.form.docMercan = []
        }
      } else {
        this.form.docMercan.imageName = ''
        this.form.docMercan.imageFile = ''
        this.form.docMercan.imageUrl = ''
      }
    },
    pickFileCI () {
      this.$refs.imageCI.click()
    },
    onFilePickedCI (e) {
      this.pdfCI = false
      const files = e.target.files
      if (files[0] !== undefined) {
        let peso = false
        for (let j = 0; j < files.length; j++) {
          if (files[j].size > 4194304) {
            peso = true
          }
        }
        if (peso === false) {
          if (files[0].type === 'application/pdf') {
            this.pdfCI = true
          }
          this.form.docCI.imageName = files[0].name
          if (this.form.docCI.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.form.docCI.imageUrl = fr.result
            this.form.docCI.imageFile = files[0] // this is an image file that can be sent to server...
          })
        } else {
          this.$swal.fire(
            'Oops...',
            'Error encontrado, la imagen debe pesar menos de 4MB.',
            'error'
          )
          this.form.docCI = []
        }
      } else {
        this.form.docCI.imageName = ''
        this.form.docCI.imageFile = ''
        this.form.docCI.imageUrl = ''
      }
    },
    pickFileRef () {
      this.$refs.imageRef.click()
    },
    onFilePickedRef (e) {
      this.pdfRef = false
      const files = e.target.files
      if (files[0] !== undefined) {
        let peso = false
        for (let j = 0; j < files.length; j++) {
          if (files[j].size > 4194304) {
            peso = true
          }
        }
        if (peso === false) {
          if (files[0].type === 'application/pdf') {
            this.pdfRef = true
          }
          this.form.docRef.imageName = files[0].name
          if (this.form.docRef.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.form.docRef.imageUrl = fr.result
            this.form.docRef.imageFile = files[0] // this is an image file that can be sent to server...
          })
        } else {
          this.$swal.fire(
            'Oops...',
            'Error encontrado, la imagen debe pesar menos de 4MB.',
            'error'
          )
          this.form.docRef = []
        }
      } else {
        this.form.docRef.imageName = ''
        this.form.docRef.imageFile = ''
        this.form.docRef.imageUrl = ''
      }
    },
    showAlertRegistro () {
      Swal.fire({
        title: '¡Felicidades!',
        text: 'Su registro se ha sido realizada con éxito, en breve validaremos tu información y le enviaremos un correo con la aprobación o rechazo de la misma.',
        icon: 'success'
      }).then((result) => {
        if (result.value) {
          window.location.href = '/ingreso'
        }
      })
    },
    showAlert3 (item) {
      Swal.fire(
        'Error inesperado',
        item,
        'error'
      )
    },
    showAlert2 () {
      Swal.fire(
        'Error inesperado',
        'Ha ocurrido un error, por favor verifique la información.',
        'error'
      )
    },
    showAlert () {
      Swal.fire({
        title: 'Error encontrado.',
        text: 'Estimado cliente, para completar el registro debe ingresar todos los campos solicitados.',
        icon: 'error'
      })
    },
    initialize () {
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/ingreso.scss";
</style>
