<template lang="pug">
  #Carrito
    Header
    div(style='background-color: gainsboro;height: 60px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 style='display: flex;justify-content: flex-start;align-items: center;')
            a(style='font:size:14px;font-weight: bold;color: #FF6200;text-decoration: initial;' href='/') Inicio
            span(style='padding-left:10px;') /
            span(style='font:size:14px;padding-left:10px;font-weight: bold;') Historial de facturas
    div
      v-layout(wrap style='display:flex;justify-content:center;margin-top:70px;')
        v-flex(xs10 style='display:flex;justify-content:center;background-color: rgb(135, 135, 135);height: 55px;align-items: center;')
          v-layout(wrap v-if='facturas.length > 0')
            v-flex(xs12 sm6)#serch
              v-text-field(outlined dense v-model='search' placeholder='Buscar...' style='padding:10px;' color='white' @keyup.enter='initialize()')
            v-flex(xs12 sm6 style='text-align: center;display: flex;justify-content: center;align-items: center;')
              span(style='color:white;') Mostrando facturas 1 - {{facturas.length}} de {{totalF}} resultados
        v-flex(xs10 style='display:flex;justify-content:center;height: 55px;align-items: center;margin-top: 10px;' v-if='pantalla >= 820')
          v-layout(wrap style='border-bottom: 1px solid #e4e7ea;padding-bottom: 5px;')
            v-flex(xs2).hed
              span CODIGO
            v-flex(xs2).hed
              span FECHA EMISIÓN
            v-flex(xs2).hed
              span FECHA VENCIMIENTO
            v-flex(xs2).hed
              span DEUDA
            v-flex(xs2).hed
              span TOTAL
            v-flex(xs2).hed
              span ACCIÓN
        v-flex(xs10 style='display:flex;justify-content:center;height: auto;align-items: center;' v-for='item in facturas')
          v-layout(wrap style='border-bottom: 1px solid #e4e7ea;padding-bottom: 12px;margin-top: 20px;position:relative;' v-if='pantalla < 820')
            v-flex(xs10 style='display: flex;align-items: center;')
              div(style='display: flex;flex-direction: column;padding: 8px;text-align: start;')
                span(style='font-size: 15px;font-weight: 500;') Factura nro: {{item.nro_doc}}
                span(style='font-size: 15px;font-weight: 500;') Fecha emisión: {{item.fec_emis | moment("DD-MM-YYYY, h:mm a")}}
                span(style='font-size: 15px;font-weight: 500;') Fecha vencimiento: {{item.fec_venc | moment("DD-MM-YYYY, h:mm a")}}
                span(style='font-size: 15px;font-weight: 500;opacity: 0.8;color:red;font-weight: bold;') Saldo: ${{item.saldo}}
                span(style='font-size: 15px;font-weight: 500;opacity: 0.8;') Total: ${{item.total_neto}}
            v-flex(xs2).centrar
              v-icon(style='cursor: pointer;' @click='editItem(item)') visibility
          v-layout(wrap style='border-bottom: 1px solid #e4e7ea;padding-bottom: 12px;margin-bottom: 20px;' v-if='pantalla >= 820')
            v-flex(xs2).centrar
              span(style='opacity: 0.7;') {{item.nro_doc}}
            v-flex(xs2).centrar
              span(style='opacity: 0.7;') {{item.fec_emis | moment("DD-MM-YYYY, h:mm a")}}
            v-flex(xs2).centrar
              span(style='opacity: 0.7;') {{item.fec_venc | moment("DD-MM-YYYY, h:mm a")}}
            v-flex(xs2).centrar
              span(style='opacity: 0.7;color:red;font-weight: bold;' v-if='item.saldo > 0') ${{item.saldo}}
              span(style='opacity: 0.7;' v-if='item.saldo === 0') ${{item.saldo}}
            v-flex(xs2).centrar
              span(style='opacity: 0.7;') ${{item.total_neto}}
            v-flex(xs2).centrar
              v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItem(item)' v-on="on") visibility
                | Ver factura
        v-flex(xs10 style='display:flex;justify-content:center;height: auto;align-items: center;' v-if='facturas.length > 0')
          v-layout(wrap style='border-bottom: 1px solid #e4e7ea;padding-bottom: 12px;margin-bottom: 20px;padding-top: 12px;' v-if='pantalla < 820')
            v-flex(xs12).centrar
              span(style='opacity: 0.8;') <b> TOTAL DEUDA: </b> <span style='opacity: 1 !important;color:red;font-weight: bold;'>${{saldoDeudor}}</span>
          v-layout(wrap style='border-bottom: 1px solid #e4e7ea;padding-bottom: 12px;margin-bottom: 20px;' v-if='pantalla >= 820')
            v-flex(xs4).centrar
            v-flex(xs2).centrar
              span(style='opacity: 0.7;') <b> TOTAL DEUDA: </b>
            v-flex(xs2).centrar
              span(style='opacity: 0.7;color:red;font-weight: bold;') ${{saldoDeudor}}
            v-flex(xs4).centrar
        v-flex(xs10 style='display:flex;justify-content:center;height: auto;align-items: center;margin-bottom:20px;')
          v-progress-circular(v-if='loading' indeterminate='', color='#c7c7c7')
          v-btn(v-if='!loading && facturas.length > 0 && facturas.length !== totalF' style='color:white;background:#ff5700;border-radius:0px;' @click='verM()').elevation-0 ver más
    v-dialog(v-model='dialog', persistent max-width='900px')
      v-container(style='background-color:white !important;max-width: 900px;padding:30px;height: 100%;')
        v-flex(xs12)
          template
            v-card(flat='')
              v-flex(xs12 md12 sm12)
                v-layout(row, wrap)
                  v-flex(xs12 style='display: flex;justify-content: center;margin-bottom:20px;')
                    div(style='display: flex;align-items: center;')
                      img(src='../assets/imgFerco/logo2.png', style='width: auto;height: 50px;margin-top: 0px;margin-bottom: 0px;object-fit: contain;')
                  v-flex(xs12 style='padding-top: 20px;background: rgb(135, 135, 135);height: 100%;display: flex;align-items: flex-end;')
                    v-layout(wrap style='line-height: 1;')
                      v-flex(xs6)
                        div(style='padding-left: 15px;')
                          p(style='font-weight: 500;').white--text Factura: {{form.nro_doc}}
                          p(style='font-weight: 500;' v-if='form.fec_emis').white--text Fecha emisión: {{form.fec_emis | moment("DD-MM-YYYY, h:mm a")}}
                          p(style='font-weight: 500;' v-if='form.fec_venc').white--text Fecha vencimiento: {{form.fec_venc | moment("DD-MM-YYYY, h:mm a")}}
                      v-flex(xs6 style='display: flex;justify-content: center;align-items: center;flex-direction: column;')
                        p(style='font-size: 21px !important;font-weight: 500;').white--text {{form.vendedor}}
                  v-flex(xs12 style='height: 45px;background: transparent;display: flex;align-items: center;border-bottom: 1px solid;')
                    v-layout(wrap style='line-height: 1;')
                      v-flex(xs6 style='padding-left: 15px;')
                        span(style='font-weight: 500;') Producto
                      v-flex(xs3)
                        span(style='font-weight: 500;display: flex;justify-content: center;') Cantidad
                      v-flex(xs3)
                        span(style='font-weight: 500;display: flex;justify-content: center;') Monto
                  v-flex(v-for='(item,index) in form.items' xs12 v-bind:class="{ 'active': par(index), 'pasive': comparar(index) }" style='height: auto;padding:10px;background: rgb(250, 250, 250);display: flex;align-items: center;')
                    v-layout(wrap style='line-height: 1;')
                      v-flex(xs6 style='padding-left: 15px;')
                        span(style='font-weight: 400;font-size: 14px;') {{item.art_des}}
                      v-flex(xs3)
                        span(style='font-weight: 400;display: flex;justify-content: center;font-size: 14px;') {{item.total_art}}
                      v-flex(xs3)
                        span(style='font-weight: 400;display: flex;justify-content: center;font-size: 14px;') ${{item.reng_neto}}
                  v-flex(xs12 style='height: 45px;background: #fff;display: flex;align-items: center;')
                  v-flex(xs12 style='height: 45px;background: rgb(250, 250, 250);display: flex;align-items: center;')
                    v-layout(wrap style='line-height: 1;')
                      v-flex(xs6)
                      v-flex(xs3 style='display: flex;justify-content: center;')
                        span(style='font-weight: 500;') Total:
                      v-flex(xs3 style='display: flex;justify-content: center;')
                        span(style='font-weight: 400 !important;font-style: italic;') ${{form.total_neto}}
              v-flex(xs12 sm12 style='margin-top:20px')
                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                  v-btn(style="background:#ff5700;border-radius:0px;color:white;", @click.native='close').elevation-0 Salir
    //===========================================================Modal Espera==============================================================================================
    v-dialog(v-model='loading2', persistent max-width='400px' style='background:#f1f2f1')
      v-container(style='max-width: 400px;background:#f1f2f1')
        div(style="margin-top: 20px;")
            v-flex(xs12 sm12)
              v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                v-flex(xs12)
                  v-card-title(style="padding: 7px;").justify-center
                    h4.black--text  Esperando Respuesta del Servidor.!!
                v-flex(xs12)
                    div(style='display: flex;justify-content: center;').text-xs-center
                      img(src='../assets/waiting.gif', with='200' ,height='150')
                v-flex(xs12)
                  v-card-title(style="padding: 7px;").justify-center
                    h5.black--text  Por favor espere...
    Footer
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Api from '@/services/methods'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { mapState, mapActions } from 'vuex'
import { serverImg } from '@/services/environment'
export default {
  name: 'Facturas',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      search: '',
      loading: false,
      loading2: false,
      saldoDeudor: 0,
      skip: 0,
      form: {
        fec_emis: '',
        fec_venc: '',
        items: [],
        nro_doc: '',
        saldo: 0,
        total_neto: 0,
        vendedor: ''
      },
      totalF: '',
      dialog: false,
      defaultForm: {
        fec_emis: '',
        fec_venc: '',
        items: [],
        nro_doc: '',
        saldo: 0,
        total_neto: 0,
        vendedor: ''
      },
      pantalla: screen.width,
      ruta: serverImg,
      facturas: []
    }
  },
  created () {
    this.initialize()
    document.documentElement.scrollTop = 0
  },
  methods: {
    calcularDeud () {
      if (this.facturas.length > 0) {
        let acum = 0
        for (let i = 0; i < this.facturas.length; i++) {
          acum = acum + this.facturas[i].saldo
        }
        return acum
      } else {
        return 0
      }
    },
    comparar (data) {
      if ((data + 1) === this.form.items.length) {
        return true
      } else {
        return false
      }
    },
    par (data) {
      if (data % 2 === 0) {
        return false
      } else {
        return true
      }
    },
    editItem (item) {
      this.loading2 = true
      Api.get('factura/' + item.nro_doc).then(res => {
        const info = res.data[0]
        Object.keys(this.form).map(key => {
          if (info[key]) this.form[key] = info[key]
        })
        this.form._id = info._id
        console.log(this.form)
        setTimeout(function () {
          this.loading2 = false
          this.dialog = true
        }.bind(this), 1000)
      }).catch(err => {
        setTimeout(function () {
          this.loading2 = false
          console.log(err)
          Swal.fire(
            'Error inesperado',
            'Problemas de conexión, por favor inténtelo nuevamente.',
            'error'
          )
        }.bind(this), 1000)
      })
    },
    close () {
      this.form = Object.assign({}, this.defaultForm)
      this.dialog = false
      // this.initialize();
    },
    verM () {
      this.loading = true
      Api.get('factura/cliWeb/' + this.$store.state.fercoWeb.co_cli + '?skip=' + this.skip + '&busqueda=' + this.search).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          this.facturas.push(res.data[i])
        }
        this.loading = false
        this.skip += 10
      }).catch(err => {
        this.loading = false
        console.log(err)
        Swal.fire(
          'Error inesperado',
          'Problemas de conexión, por favor inténtelo nuevamente.',
          'error'
        )
      })
    },
    initialize () {
      if (this.$store.state.fercoWeb.co_cli) {
        this.loading = true
        this.skip = 0
        Api.get('factura/cliWeb/' + this.$store.state.fercoWeb.co_cli + '?skip=' + this.skip + '&busqueda=' + this.search).then(res => {
          this.facturas = res.data
          if (this.facturas && this.facturas.length > 0) this.saldoDeudor = this.facturas[0].cliente.saldo_deudor.toFixed(2)
          this.skip += 10
          this.loading = false
        }).catch(err => {
          this.loading = false
          console.log(err)
        })
        Api.get('factura/cliCont/' + this.$store.state.fercoWeb.co_cli + '?busqueda=' + this.search).then(res => {
          this.totalF = res.data
        })
      }
    }
  }
}
</script>
<style lang="scss">
.active {
  background: #fff !important;
}
.pasive {
  border-bottom: 1px solid !important;
}
@import "~@/assets/scss/carrito.scss";
</style>
