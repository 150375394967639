<template lang="pug">
  #Ingreso
    Header
    div(style='background-color: gainsboro;height: 60px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 style='display: flex;justify-content: flex-start;align-items: center;')
            a(style='font:size:14px;font-weight: bold;color: #FF6200;text-decoration: initial;' href='/') Inicio
            span(style='padding-left:10px;') /
            span(style='font:size:14px;padding-left:10px;font-weight: bold;') Ingresar
    div(style='height: auto;display: flex;justify-content: center;align-items: center;')
      v-flex(xs10)
        v-layout(wrap style='margin-top:70px;')
            v-flex(xs12 sm6 style='margin-bottom:60px;padding:15px')
                h2(style='margin-bottom:10px;') INICIAR SESIÓN
                v-card(style='border:1px solid #eeeeee;padding:30px;height: 90%;').elevation-0
                    div
                        span(style='font-weight:500;') Correo Electrónico
                        v-text-field(outlined v-model='form.email' placeholder='Correo electrónico' color='#878787')
                    div
                        span(style='font-weight:500;') Contraseña
                        v-text-field(outlined v-model='form.password' type='password' placeholder='Contraseña' color='#878787')
                    div(style='display:flex;flex-direction:column;align-items: baseline;')
                        v-btn(style='background:#ff5700;color:white;border-radius:0px;' @click='login()').elevation-0 Iniciar Sesión
                        a(style='color:black;opacity:0.6;font-weight:500;margin-top:10px;' href='/recuperar') ¿Olvidaste tu contraseña?
            v-flex(xs12 sm6 style='margin-bottom:60px;padding:15px')
                h2(style='margin-bottom:10px;') NUEVO CLIENTE
                v-card(style='border:1px solid #eeeeee;padding:30px;height: 90%;').elevation-0
                    div(style='margin-bottom:15px;')
                        span(style='font-weight:500;font-size:15px;') CREAR UNA CUENTA
                    div(style='line-height: 1.7;')
                        span(style='font-weight:400;font-size:14px;') Regístrese para obtener una cuenta gratuita en nuestra tienda. Registrarse es facil y rapido. Le permite realizar pedidos en nuestra tienda. Para comenzar a comprar, haga click en registrarse.
                    div(style='display:flex;flex-direction:column;align-items: baseline;margin-top:15px;')
                        v-btn(style='background:#ff5700;color:white;border-radius:0px;' to='/registrarse').elevation-0 registrarse
    Footer
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Api from '@/services/methods'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { mapState, mapActions } from 'vuex'
import { serverImg } from '@/services/environment'
export default {
  name: 'Ingreso',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      pantalla: screen.width,
      ruta: serverImg
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    login () {
      if (this.form.email && this.form.password) {
        Api.post('clientesWeb/login', this.form)
          .then(response => {
            if (response.data.status !== 'denied') {
              this.$store.dispatch('login', response.data)
              this.$cookies.set('token', response.data.token, '5D', '')
              window.location.href = '/'
            } else {
              Swal.fire(
                'Oops...',
                response.data.message,
                'error'
              )
            }
          })
          .catch(error => {
            Swal.fire(
              'Oops...',
              'Error de conexión, intente nuevamente o contacte con el administrador.',
              'error'
            )
            console.log(error)
          })
      } else {
        this.showAlert()
      }
    },
    showAlert () {
      Swal.fire({
        title: 'Error encontrado.',
        text: 'Estimado cliente, debe ingresar usuario y contraseña para ingresar.',
        icon: 'error'
      })
    },
    initialize () {
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/ingreso.scss";
</style>
