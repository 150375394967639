<template lang="pug">
  #Header
    div(style="background-color: white;color: white;height: 50px;display: flex;justify-content:center;width: 100%;z-index: 100;border-bottom: 0.5px solid black;")
      v-flex(xs12 sm11 md11)
        v-layout(wrap style='height: 100%;display: flex;align-content: center;')
          v-flex(v-if='!email' xs12 sm12 md12 class='centerHd')
            v-btn(class='btnNew' to='/ingreso').elevation-0 Ingresar
            div(class='divSepr')
            v-btn(class='btnNew' to='/registrarse').elevation-0 Registrarse
          v-flex(v-if='email' xs12 sm12 md12 class='centerHd')
            v-menu(key='Custom' rounded='b-xl' offset-y='' style='z-index: 10000;')
              template(v-slot:activator='{ attrs, on }')
                v-btn(class='btnNew' v-bind='attrs' v-on='on').elevation-0
                  | {{ email }}
              v-list
                v-list-item(v-for='item in items2' :key='item.title' link='' @click='seleccion(item)')
                  v-list-item-title(v-text='item.title')
    div
      v-card(style='box-shadow: 0px 8px 5px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;')
        v-layout(wrap justify-center)
          v-flex(xs12 sm12 md3 class='centrarAll')
            img(src='@/assets/imgFerco/logo2.png' class='imgLogo' @click='irHome()')
          v-flex(xs11 sm11 md6 class='centrarAll')
            div(style='display:flex;width: 100%;position:relative;' v-click-outside='handleClickOutside')
              v-text-field(ref='inpSearch' @focus='handleClickInside()' v-model="searchText" color='grey' @keyup="handleSearchProduct($event)" :loading='(isLoading) ? "primary" : false' placeholder='Estoy buscando' single-line='' outlined='' hide-details='')
              v-btn(class='btnBuscar' @click='irProductos()').elevation-0 Buscar
              v-list(v-if='isSearching' class='busq')
                v-list-item-group(v-if='searchResults && searchResults.length > 0' style='height: 270px;overflow-x: auto;')
                  v-list-item(v-for='(item, i) in searchResults' :key='i' @click='irDetalle(item._id)' style='padding: 6px;border-bottom: 1px solid #d1cfcf;')
                    v-layout(wrap)
                      v-flex(xs2 style='display: flex;justify-content: center;align-items: center;')
                        img(v-if='item.imagen && item.imagen[0]' :src='ruta + item.imagen[0].url' style='width: auto;height: 50px;object-fit: contain;')
                        img(v-else src='@/assets/imgFerco/image.png' style='width: auto;height: 50px;object-fit: contain;')
                      v-flex(xs10 style='display: flex;flex-direction: column;justify-content: center;')
                        span(style='font-size:12px;') {{item.art_des}}
                        div(v-if='!item.stock || item.stock <= 0' style='margin-bottom:6px;')
                          span(style='background: red;font-size: 12px;color: white;border-radius: 40px;padding: 1px 6px 1px 6px;') Sin stock
                        div(v-if='mostrarS' style='margin-top: -4px;')
                          span(v-if='item.stock && item.stock > 0' style='font-size:12px;') Existencia:
                          span(v-if='item.stock && item.stock > 0' style='font-size:12px;color:green;padding-left:4px;') {{item.stock}}
                        span(v-if='mostrarP' style='font-size:12px;color:red;') ${{item.precio}}
                div(v-if='policeRes' style='display: flex;justify-content: center;padding: 20px;font-size: 17px;')
                  span No se han conseguido resultados.
                div(v-if='!policeRes && searchResults && searchResults.length > 0' style='display: flex;justify-content: center;margin-top: 10px;font-size: 14px;')
                  a(@click='irProductos()') Ver todos los resultados

          v-flex(xs12 sm12 md3 class='centrarEnd2' style='position:relative;')
            div(@click='mostrarC = !mostrarC' style='position:relative;cursor:pointer;')
              div(v-if='car && car.length > 0' class='divCar')
                span {{car.length}}
              img(src='@/assets/imgFerco/carrito.png' style='height:60px;width:auto;object-fit:contain;padding:5px;margin:10px;')
            div(v-if='mostrarC' class='divDetailCar')
              div(class='divDetailTitle')
                span Carrito de compra
                v-btn(@click='mostrarC = false' icon style='background:transparent;').elevation-0
                  img(src='@/assets/imgFerco/x.png' style='height:16px;width:16px;object-fit:contain;')
              div(style='height: 310px;margin-bottom: 15px;padding-top: 20px;overflow-y: auto;')
                div(v-for='item in car' style='border-bottom: 1px solid grey;padding-bottom: 15px;')
                  v-flex(xs12)
                    v-layout(wrap)
                      v-flex(xs3 style='display: flex;justify-content: center;align-items: center;')
                        div(style='display: flex;justify-content: center;align-items: center;position: relative;width: 90%;')
                          img(v-if='item.imagen && item.imagen[0]' :src='ruta + item.imagen[0].url' style='height:75px;width:100%;object-fit:contain;')
                          img(v-else src='@/assets/imgFerco/image.png' style='width: auto;height: 50px;object-fit: contain;')
                      v-flex(xs7)
                        span(style='font-size: 13px;font-weight: bold;') {{item.art_des}}
                        div(style='display:flex;padding-top:5px;')
                          span(style='color:#0F4DFF;font-size: 13px;font-weight: bold;') ${{item.precio}}
                          span(style='margin-left:5px;font-size: 13px;') (x{{item.cant}})
                      v-flex(xs2 style='display: flex;justify-content: center;align-items: center;')
                        v-btn(@click='borrar(item)' icon)
                          img(src='@/assets/imgFerco/basura.png' style='height:20px;width:20px;object-fit:contain;')
                div(v-if='!car || car.length == 0' style='display: flex;flex-direction:column;justify-content: flex-end;align-items: center;height: 290px;padding-bottom: 20px;')
                  img(src='@/assets/imgFerco/no hay articulos.png' style='height:150px;width:100%;object-fit:contain;padding-right: 40px;')
                  span(style='margin-top: 20px;font-weight: 600;') ¡No has añadido productos aún!
              div(style='display: flex;flex-direction: column;')
                v-btn(v-if='car && car.length > 0' @click='irCarrito()' style='background-color:#0F4DFF;height: 40px;border-radius: 5px;color:white;text-transform: inherit;').elevation-0 Ver carrito
                v-btn(@click='irProduc()' style='background-color:#0F4DFF;height: 40px;border-radius: 5px;color:white;text-transform: inherit;margin-top:10px;').elevation-0 Ver productos

</template>
<script>
import { serverImg } from '@/services/environment'
import vClickOutside from 'v-click-outside'
import { EventBus } from '../Events.js'
import Api from '@/services/methods'
export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      sheet: false,
      mostrarC: false,
      selector: 0,
      settings: {},
      car: [],
      count: 0,
      mostrarP: false,
      mostrarS: false,
      isLoading: false,
      isSearching: false,
      policeRes: false,
      searchResults: [],
      searchText: '',
      email: '',
      ruta: serverImg,
      items2: [],
      badge: 0
    }
  },
  watch: {
    searchText (val) {
      if (!val) {
        this.cont = 0
        this.isLoading = false
        this.policeRes = false
        this.isSearching = false
        this.searchResults = []
      }
    }
  },
  created () {
    if (this.$store.state.carFerco !== null) {
      this.car = this.$store.state.carFerco
    }
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb._id) {
      this.items2 = [
        {
          title: 'Perfil'
        },
        {
          title: 'Historial de facturas'
        },
        {
          title: 'Historial de pedidos'
        },
        {
          title: 'Cerrar Sesión'
        }
      ]
    }
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb.co_cli) {
      this.mostrarP = true
      this.email = this.$store.state.fercoWeb.email
    }
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb.stock) {
      this.mostrarS = this.$store.state.fercoWeb.stock
    }
  },
  mounted () {
    EventBus.$on('vaciar', data => {
      this.car = this.$store.state.carFerco
    })
    EventBus.$on('limpiarSearch', data => {
      this.cont = 0
      this.isLoading = false
      this.policeRes = false
      this.isSearching = false
      this.searchResults = []
      this.searchText = ''
    })
    EventBus.$on('actCarrito', data => {
      if (this.$store.state.carFerco !== null) {
        this.car = this.$store.state.carFerco
      }
    })
  },
  methods: {
    borrar (item) {
      this.$store.dispatch('eliminar', item)
      this.car = this.$store.state.car
    },
    irHome () {
      this.$router.push('/')
    },
    irCarrito () {
      this.$router.push('/carrito')
    },
    irDetalle (item) {
      if (this.$route.name == 'Detalle') {
        this.$router.push('/detalle/' + item)
        EventBus.$emit('buscarDetalle', item)
      } else {
        this.$router.push('/detalle/' + item)
      }
    },
    irProduc () {
      this.$router.push({ name: 'Productos' })
    },
    irProductos () {
      if (this.$route.name == 'Productos') {
        EventBus.$emit('buscarProducto', this.searchText)
      } else {
        this.$router.push({ name: 'Productos', params: { value: this.searchText } })
      }
    },
    async handleSearchProduct (item) {
      if (item.key == 'Enter') {
        if (this.searchText) {
          this.$refs.inpSearch.blur()
          this.handleClickOutside()
          this.irProductos()
        }
      } else {
        this.policeRes = false
        this.count++
        const contador = this.count

        if (this.searchText !== '') {
          setTimeout(
            async function (cont) {
              if (cont == this.count) {
                this.isLoading = true
                const cli = (this.$store.state.fercoWeb && this.$store.state.fercoWeb.co_precio) ? this.$store.state.fercoWeb.co_precio.trim() : null
                Api.get('productosCom/searchWeb?data=' + this.searchText + '&codigo=' + cli).then(res => {
                  if (res.data) {
                    setTimeout(
                      function () {
                        this.searchResults = res.data
                        if (!this.searchResults || this.searchResults.length == 0) this.policeRes = true
                        this.isLoading = false
                        this.isSearching = true
                      }.bind(this),
                      500
                    )
                  }
                  if (res.data && res.data[0]) {
                    this.settings = res.data[0]
                  }
                })
              }
            }.bind(this),
            800,
            contador
          )
        }
      }
    },
    handleClickInside () {
      if (this.searchText && this.searchResults && this.searchResults.length > 0) {
        this.isSearching = true
      }
    },
    handleClickOutside () {
      this.isSearching = false
      this.policeRes = false
      this.isLoading = false
    },
    seleccion (item) {
      if (item.title === 'Cerrar Sesión') {
        this.$store.dispatch('logout')
        window.location.href = '/'
      }
      if (item.title === 'Ingresar') {
        window.location.href = '/ingreso'
      }
      if (item.title === 'Perfil') {
        window.location.href = '/perfil'
      }
      if (item.title === 'Historial de pedidos') {
        window.location.href = '/historial'
      }
      if (item.title === 'Historial de facturas') {
        window.location.href = '/facturas'
      }
    },
    revisar () {
      if (this.$store.state.fercoWeb && this.$store.state.fercoWeb._id) {
        this.$router.push('/transaccion')
      } else {
        this.$router.push('/ingreso')
      }
    },
    ir () {
      this.$router.push('/buscar')
    },
    vercarrito () {
      this.$router.push('/carrito')
    },
    logout () {
      this.$store.dispatch('logout')
      window.location.href = '/'
    },
    seleccion (item) {
      if (item.title === 'Cerrar Sesión') {
        this.$store.dispatch('logout')
        window.location.href = '/'
      }
      if (item.title === 'Ingresar') {
        window.location.href = '/ingreso'
      }
      if (item.title === 'Perfil') {
        window.location.href = '/perfil'
      }
      if (item.title === 'Historial de pedidos') {
        window.location.href = '/historial'
      }
      if (item.title === 'Historial de facturas') {
        window.location.href = '/facturas'
      }
    },
    initialize () {
      Api.get('settings').then(res => {
        if (res.data && res.data[0]) {
          this.settings = res.data[0]
        }
      })
    },
    borrar (item) {
      this.$store.dispatch('eliminar', item)
      this.car = this.$store.state.carFerco
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/header.scss";
.divDetailTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid grey;
}
.divCar {
  height: 40px;
  width: 40px;
  background: rgb(15, 77, 255);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  right: 10px;
  top: 5px;
}
.ps-form--quick-search {
  .ps-form__input {
    position: relative;
    width: 100%;
    .v-progress-circular {
      // @include vertical-align();
      right: 10px;
      z-index: 20;
    }
    *::-webkit-input-placeholder {
      /* Google Chrome y Safari */
      color: #ffffff;
      font-style: italic;
    }
    *:-moz-placeholder {
      /* Firefox anterior a 19 */
      color: #ffffff;
      font-style: italic;
    }
    *::-moz-placeholder {
      /* Firefox 19 y superior */
      color: #ffffff;
      font-style: italic;
    }
    *:-ms-input-placeholder {
      /* Internet Explorer 10 y superior */
      color: #ffffff;
      font-style: italic;
    }
  }
}
</style>
