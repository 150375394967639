<template lang="pug">
  v-app
    v-content
      router-view
</template>

<script>
import Header from './components/Header';

export default {
  name: 'App',

  components: {
    Header,
  },

  data: () => ({
    //
  }),
};
</script>
<style>
::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
  transition-property: background-color;
  transition-duration: 3s;
  transition-delay: 1s;
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #d5d5d5;
}
::-webkit-scrollbar-thumb:hover {
  transition-property: background-color;
  transition-duration: 3s;
  transition-delay: 1s;
  background-color: #24242494; 
}
</style>