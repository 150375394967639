<template lang="pug">
  #Ingreso
    Header
    div(style='background-color: gainsboro;height: 60px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 style='display: flex;justify-content: flex-start;align-items: center;')
            a(style='font:size:14px;font-weight: bold;color: #FF6200;text-decoration: initial;' href='/') Inicio
            span(style='padding-left:10px;') /
            span(style='font:size:14px;padding-left:10px;font-weight: bold;') Acerca de nosotros
    div(style='height: auto;display: flex;justify-content: center;align-items: center;')
      v-flex(xs12)
        v-layout(wrap style='margin-bottom:40px;')
          v-flex(xs12 v-if='police')
            v-skeleton-loader.mx-auto#ld4(style='width:100%;' type='image')
          v-flex(xs12 v-if='acerca && acerca.imagen')
            img(:src='ruta + acerca.imagen' style='height:auto;width:100%;object-fit:content;object-fit: contain;')
    div(style='height: auto;display: flex;justify-content: center;align-items: center;')
      v-flex(xs10 sm8)
        v-layout(wrap style='margin-bottom:40px;')
            v-flex(xs12 style='margin-top:60px;text-align:center;')
                h2(style='font-weight: 900;') ¿Quiénes somos?
            v-flex(xs12 style='margin-top:80px;text-align:center;' v-if='police')
              v-skeleton-loader.mx-auto#ld5(style='width:100%;' type='image')
            v-flex(xs12 style='margin-top:80px;text-align:center;' v-if='acerca && acerca.mision')
                h2(style='font-weight: 900;margin-bottom: 20px;') Misión
                span(style='font-size: 17px;') {{acerca.mision}}
            v-flex(xs12 style='margin-top:80px;text-align:center;' v-if='police')
              v-skeleton-loader.mx-auto#ld5(style='width:100%;' type='image')
            v-flex(xs12 style='margin-top:60px;text-align:center;' v-if='acerca && acerca.vision')
                h2(style='font-weight: 900;margin-bottom: 20px;') Visión
                span(style='font-size: 17px;') {{acerca.vision}}
            v-flex(xs12 style='margin-top:80px;text-align:center;' v-if='police')
              v-skeleton-loader.mx-auto#ld5(style='width:100%;' type='image')
            v-flex(xs12 style='margin-top:60px;text-align:center;' v-if='acerca && acerca.valores')
                h2(style='font-weight: 900;margin-bottom: 20px;') Valores
                span(style='font-size: 17px;') {{acerca.valores}}
    Footer
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Api from '@/services/methods'
import { serverImg } from '@/services/environment'
export default {
  name: 'Acerca',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      acerca: {},
      police: true,
      ruta: serverImg
    }
  },
  created () {
    document.documentElement.scrollTop = 0
    this.initialize()
  },
  methods: {
    initialize () {
      this.police = true
      Api.get('quienes').then(res => {
        this.acerca = res.data[0]
        this.police = false
      })
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/ingreso.scss";
</style>
