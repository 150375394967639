<template lang="pug">
  #Home.ld
    Header(style='position: relative;z-index: 10000;')
    v-skeleton-loader.mx-auto#ld(v-if='policeCarousel' max-height='400' style='width:100%;' type='image')
    div(v-if='carousel && carousel.length > 0')
      v-flex(xs12 style='height: auto;display: flex;position:relative;')
        v-carousel(height='auto' hide-delimiters='')
          v-carousel-item(v-for='(item, i) in carousel' :key='i')
            img(:src='ruta + item.imagen' style='height:auto;width:100%;object-fit:cover;')
    div(style='display: flex;justify-content: center;margin-top: 70px;margin-bottom: 50px;')
      v-flex(xs11 sm10 md10)
        v-layout(wrap style='display: flex;align-content: center;')
          v-flex(xs12 sm6 md3 class='infoDiv')
            img(src='@/assets/imgFerco/papel.png' style='height:50px;width:auto;object-fit:contain;')
            div(style='display: flex;flex-direction: column;padding-left: 15px;')
              span(style='font-size: 15px;font-weight: bold;') Mayor ferretero
              span(style='font-size: 13px;font-weight: 300;') gran variedad de stock
          v-flex(xs12 sm6 md3 class='infoDiv')
            img(src='@/assets/imgFerco/people.png' style='height:50px;width:auto;object-fit:contain;')
            div(style='display: flex;flex-direction: column;padding-left: 15px;')
              span(style='font-size: 15px;font-weight: bold;') Equipo comprometido
              span(style='font-size: 13px;font-weight: 300;') con nuestros clientes
          v-flex(xs12 sm6 md3 class='infoDiv')
            img(src='@/assets/imgFerco/camion.png' style='height:50px;width:auto;object-fit:contain;')
            div(style='display: flex;flex-direction: column;padding-left: 15px;')
              span(style='font-size: 15px;font-weight: bold;') Entregas
              span(style='font-size: 13px;font-weight: 300;') inmediatas y seguras
          v-flex(xs12 sm6 md3 class='infoDiv2')
            img(src='@/assets/imgFerco/tienda.png' style='height:50px;width:auto;object-fit:contain;')
            div(style='display: flex;flex-direction: column;padding-left: 15px;')
              span(style='font-size: 15px;font-weight: bold;') Llegamos hasta
              span(style='font-size: 13px;font-weight: 300;') cada establecimiento
    div(v-if='policeBanners' style='display: flex;justify-content: center;margin-top: 20px;margin-bottom: 50px;')
      v-flex(xs11 sm10 md10)
        v-layout(wrap style='display: flex;align-content: center;')
          v-flex(xs12 sm12 md6 class='pddr')
            v-skeleton-loader.mx-auto#ld2(style='width:100%;' type='image')
          v-flex(xs12 sm12 md6 class='pddl')
            v-skeleton-loader.mx-auto#ld2(style='width:100%;' type='image')
    div(v-if='bannersI && bannersD' style='display: flex;justify-content: center;margin-top: 20px;margin-bottom: 50px;')
      v-flex(xs11 sm10 md10)
        v-layout(wrap style='display: flex;align-content: center;')
          v-flex(xs12 sm12 md6 class='pddr')
            img(:src='ruta + bannersI.imagen' style='height:250px;width:100%;object-fit:contain;')
          v-flex(xs12 sm12 md6 class='pddl')
            img(:src='ruta + bannersD.imagen' style='height:250px;width:100%;object-fit:contain;')
    div(v-if='policeMarcas' style='display: flex;justify-content: center;margin-top: 20px;margin-bottom: 50px;')
      v-flex(xs11 sm10 md10)
        v-layout(wrap)
          v-flex(xs12)
            div(style='width: 200px;margin-bottom: 20px;')
              v-skeleton-loader.mx-auto#ld2(max-height='30' max-width='200' type='image')
          v-flex(xs12)
            carousel(:perPageCustom="[[320, 1],[480, 2], [768, 4], [1160, 6]]")
              slide(v-for='(item,i) in arraySke' style='padding-left: 5px;padding-right: 5px;')
                div.carouselMarkSke
                  v-skeleton-loader.mx-auto#ld3(style='width:100%;' type='image')
    div(v-if='marcasWeb && marcasWeb.length > 0' style='display: flex;justify-content: center;margin-top: 20px;margin-bottom: 50px;')
      v-flex(xs11 sm10 md10)
        v-layout(wrap)
          v-flex(xs12)
            h2(style='font-weight: 300;') Marcas destacadas
          v-flex(xs12)
            carousel(:perPageCustom="[[320, 1],[480, 2], [768, 4], [1160, 6]]")
              slide(v-for='(item,i) in marcasWeb' style='padding-left: 5px;padding-right: 5px;')
                div(@click='irProdMarca(item)' style='cursor:pointer;').carouselMark
                  div.imgCarouselDiv
                    img(:src='ruta + item.imagen' style='height:120px;width:126px;object-fit:contain;')
                  span {{item.cat_des}}
    div(v-if='policeCategorias' style='background:#e8e8e8;')
      div(style='display: flex;justify-content: center;margin-top: 20px;margin-bottom: 50px;padding-top:30px;padding-bottom:30px;')
        v-flex(xs11 sm10 md10)
          v-layout(wrap)
            v-flex(xs12)
              div(style='width: 200px;margin-bottom: 20px;')
                v-skeleton-loader.mx-auto#ld2(max-height='30' max-width='200' type='image')
            v-flex(xs12)
              v-card(style='background:white;padding: 50px;')
                v-layout(wrap)
                  v-flex(xs12 sm12 md6)
                    v-skeleton-loader.mx-auto#ld4(style='100%' type='image')
                  v-flex(xs12 sm6 md3 v-for='(prod,i) in arraySkeP' v-bind:class="{ 'flexImgSke': i != 2, 'flexImgSke2': i == 2  }")
                    div(class='divImg')
                      v-skeleton-loader.mx-auto#ld3(style='width:100%;' type='image')
    div(v-if='categoriasWeb && categoriasWeb.length > 0' style='background:#e8e8e8;')
      div(v-for='(item,i) in categoriasWeb' style='display: flex;justify-content: center;margin-top: 20px;margin-bottom: 50px;padding-top:30px;padding-bottom:30px;')
        v-flex(xs11 sm10 md10)
          v-layout(wrap)
            v-flex(xs12)
              h2(style='font-weight: 300;') {{item.subl_des}}
            v-flex(xs12)
              v-card(style='background:white;padding: 50px;')
                v-layout(wrap)
                  v-flex(xs12 sm12 md6 style='display:flex;')
                    img(@click='irProductos(item)' :src='ruta + item.imagen' style='height:280px;width:100%;object-fit:contain;cursor:pointer;')
                  v-flex(xs12 sm6 md3 v-for='(prod,i) in item.productos' v-bind:class="{ 'flexImg': i <= 1, 'flexImg2': i > 1  }")
                    div(@click='ir(prod._id)' class='divImg' style='cursor:pointer;')
                      img(:src='ruta + prod.imagen[0].url' class='imgMarc')
                      div(style='display: flex;flex-direction: column;height:auto;')
                        span(style='font-weight: 500;') {{prod.art_des}}
                        span(style='font-weight: 300;') {{prod.marcaN}}
                        span(v-if='mostrarS' style='font-size: 14px;font-weight: 500;color: grey;margin-top: 5px;') Existencia: {{prod.stock}}
                        span(v-if='mostrarP' style='font-size: 16px;font-weight: bold;color:red;') ${{prod.precio}}
    Footer
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Api from '@/services/methods'
import { serverImg } from '@/services/environment'
export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      arraySke: ['1', '2', '3', '4', '5', '6', '7', '8'],
      arraySkeP: ['1', '2', '3', '4', '5', '6'],
      policeCarousel: true,
      policeCategorias: true,
      policeBanners: true,
      policeMarcas: true,
      categoriasWeb: [],
      marcasWeb: [],
      carousel: [],
      bannersD: null,
      bannersI: null,
      mostrarP: false,
      mostrarS: false,
      tamanno: window.screen.width,
      ruta: serverImg
    }
  },
  created () {
    this.initialize()
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb.co_cli) {
      this.mostrarP = true
    }
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb.stock) {
      this.mostrarS = this.$store.state.fercoWeb.stock
    }
  },
  methods: {
    ir (item) {
      this.$router.push('/detalle/' + item)
    },
    irProdMarca (item) {
      this.$router.push({ name: 'Productos', params: { marca: item } })
    },
    irProductos (item) {
      this.$router.push({ name: 'Productos', params: { categoria: item } })
    },
    async initialize () {
      const cli = (this.$store.state.fercoWeb && this.$store.state.fercoWeb.co_precio) ? this.$store.state.fercoWeb.co_precio.trim() : null
      await Api.get('categoriaWeb/withProduct?codigo=' + cli).then(res => {
        this.categoriasWeb = res.data
        this.policeCategorias = false
      })
      await Api.get('marcas/withImg').then(res => {
        this.marcasWeb = res.data
        this.policeMarcas = false
      })
      await Api.get('carousel').then(res => {
        this.carousel = res.data
        this.policeCarousel = false
      })
      await Api.get('banners').then(res => {
        for (let i = 0; i < res.data.length; i++) {
          const el = res.data[i]
          if (el.posicion == 'Izquierda') this.bannersI = el
          if (el.posicion == 'Derecha') this.bannersD = el
        }
        this.policeBanners = false
      })
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/home.scss";
</style>
