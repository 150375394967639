<template lang="pug">
  v-container
    v-dialog(v-model="modal" persistent max-width="800")
      template(style="max-width: 800px;")
        v-card(style="padding:20px;")
          v-card-text
            v-layout(wrap="" style="margin-top: 20px;")
              v-flex(xs12 style='display: flex;justify-content: flex-end;margin-bottom: 10px;')
                v-btn(icon style="background:grey;border-radius:10px;color:white;", @click.native='close') X
              v-flex(xs12 style="padding:2px;")
                v-text-field(
                  outlined=""
                  dense=""
                  type="text"
                  label="Buscar ..."
                  hide-details=""
                  v-model="search"
                )
              v-flex(xs12 v-if='datos && datos.length > 0')
                  v-data-table.elevation-1(:headers='headers' :search='search' :items="datos" style="margin-top: 5px;")
                    template(v-slot:item.nombre='{ item }')
                        div(style='display:flex;justify-content:center;align-items:center;cursor:pointer;' @click='seleccion(item)')
                            span {{ item.nombre }}
</template>
<script>
export default {
  components: {},
  props: ["modal", "resultados", "tipo"],
  data: () => ({
    search: "",
    datos: [],
    headers: [
      { text: "Nombre", align: "center", value: "nombre" },
    ]
  }),
  created() {
    for (let i = 0; i < this.resultados.length; i++) {
        const el = this.resultados[i];
        if(this.tipo == "cat") this.datos.push({ ...el, nombre: el.subl_des })
        if(this.tipo == "subcat") this.datos.push({ ...el, nombre: el.des_color })
        if(this.tipo == "marc") this.datos.push({ ...el, nombre: el.cat_des })
    }
  },
  methods: {
    seleccion(item) {
        this.$emit("action", item);
    },
    close() {
        this.$emit("close");
    },
  }
};
</script>
<style lang="scss">
</style>