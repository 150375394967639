<template lang="pug">
  #Carrito
    Header
    div(style='background-color: gainsboro;height: 60px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 style='display: flex;justify-content: flex-start;align-items: center;')
            a(style='font:size:14px;font-weight: bold;color: #FF6200;text-decoration: initial;' href='/') Inicio
            span(style='padding-left:10px;') /
            span(style='font:size:14px;padding-left:10px;font-weight: bold;') Carrito
    div
      v-layout(wrap style='display:flex;justify-content:center;margin-top:70px;')
        v-flex(xs10 style='display:flex;justify-content:center;background-color: #878787;height: 55px;align-items: center;')
          //- v-btn(style='background:#f2ecdb' @click='revisar()').elevation-0 Revisa
        v-flex(xs10 style='display:flex;justify-content:center;height: 55px;align-items: center;margin-top: 10px;' v-if='pantalla >= 820')
          v-layout(wrap style='border-bottom: 1px solid #e4e7ea;padding-bottom: 5px;')
            v-flex(xs2).hed
              span IMAGEN
            v-flex(xs2).hed
              span NOMBRE DEL PRODUCTO
            v-flex(xs2).hed
              span PRECIO
            v-flex(xs2).hed
              span CANTIDAD
            v-flex(xs2).hed
              span ACCIÓN
            v-flex(xs2).hed
              span TOTAL
        v-flex(xs10 style='display:flex;justify-content:center;height: auto;align-items: center;' v-for='item in car')
          v-layout(wrap style='border-bottom: 1px solid #e4e7ea;padding-bottom: 12px;margin-top: 20px;position:relative;' v-if='pantalla < 820')
            v-flex(xs4 style='display: flex;justify-content: center;')
              div(style='background: rgb(250, 250, 250);width:80px;height:80px;')
                img(v-if='item.imagen && item.imagen[0]' :src='ruta+ item.imagen[0].url' style='width:100%;height:100%;padding:10px;object-fit: contain;')
                img(v-else src='@/assets/imgFerco/image.png' style='width:100%;height:100%;padding:10px;object-fit: contain;')
            v-flex(xs7 style='display: flex;align-items: center;')
              div(style='display: flex;flex-direction: column;padding: 8px;text-align: start;')
                span(style='font-size: 15px;font-weight: 500;') {{item.art_des}}
                span(style='font-size: 15px;font-weight: 500;') <i>{{item.cant}} X</i> <b style='opacity: 0.6;'> ${{item.precio}}</b>
                span(style='font-size: 15px;font-weight: 500;opacity: 0.8;') Total: ${{subtotal(item)}}
            v-flex(xs1 style='display: flex;align-items: center;justify-content:center;')
              img(src='../assets/Iconos/Icono Eliminar.png' style='width:45px;height:auto;cursor: pointer;' @click='borrar(item)')
          v-layout(wrap style='border-bottom: 1px solid #e4e7ea;padding-bottom: 12px;margin-bottom: 20px;' v-if='pantalla >= 820')
            v-flex(xs2).centrar
              div(style='background: rgb(250, 250, 250);width:100px;height:100px;')
                img(v-if='item.imagen && item.imagen[0]' :src='ruta+ item.imagen[0].url' style='width:100%;height:100%;padding:10px;object-fit: contain;')
                img(v-else src='@/assets/imgFerco/image.png' style='width:100%;height:100%;padding:10px;object-fit: contain;')
            v-flex(xs2).centrar
              span(style='opacity: 0.7;') {{item.art_des}}
            v-flex(xs2).centrar
              span(style='font-size: 24px;' v-if='mostrarP') ${{item.precio}}
              span(style='font-size: 24px;' v-if='!mostrarP') - -
            v-flex(xs2).centrar.btnNP
              div
                v-icon(color='black' style='font-size: 22px;padding: 6px;border: 1px solid #b7b7b7;cursor: pointer;padding-left: 2px;padding-right: 2px;' @click='restar(item)') keyboard_arrow_left
                span(style='border-top: 1px solid #b7b7b7;border-bottom: 1px solid #b7b7b7;padding:6px 10px 9px;') {{item.cant}}
                v-icon(color='black' style='font-size: 22px;padding: 6px;border: 1px solid #b7b7b7;cursor: pointer;padding-left: 2px;padding-right: 2px;' @click='sumar(item)') keyboard_arrow_right
            v-flex(xs2).centrar
              v-icon(style='cursor: pointer;' @click='borrar(item)') clear
            v-flex(xs2).centrar
              span(style='font-size: 24px;opacity: 0.7;' v-if='mostrarP')  ${{subtotal(item)}}
              span(style='font-size: 24px;opacity: 0.7;' v-if='!mostrarP')  - -
        v-flex(xs10 sm10 style='display:flex;justify-content:flex-end;height: auto;align-items: center;padding-right: 3%;padding-top: 10px;')
          v-layout(wrap)
            v-flex(xs12 style='text-align:end;')
              span(style='font-weight: bold;font-size: 15px;opacity: 0.8;padding-right: 50px;') Precio Total:
              span(style='font-size: 24px;font-weight: 500;' v-if='mostrarP') ${{total}}
              span(style='font-size: 24px;font-weight: 500;' v-if='!mostrarP') - -
        v-flex(v-if='car && car.length > 0' xs10 sm10 style='display:flex;justify-content:flex-end;height: auto;align-items: flex-end;padding-right: 3%;flex-direction: column;margin-bottom:30px;').ctn
          div(style='display: flex;align-items: center;margin-bottom: 30px;')
            v-checkbox(v-model='checkk' hide-details='')
            div(style='height: 5px;padding-left: 10px;')
              div
                | Acepto los
                v-tooltip(bottom='')
                  template(v-slot:activator='{ on }')
                    a(@click='dialog = !dialog' v-on='on')
                      | Términos y Políticas de Devolución
                  |             Ver políticas
          div.btn2
            v-btn(style='background-color:#ff5700;border-radius:0px;color:white;' @click='limpiar()').elevation-0 limpiar carrito
            v-btn(style='background-color:#ff5700;border-radius:0px;color:white;margin-left:5px;' :disabled='!checkk' @click='revisar()').elevation-0.btnn procesar compra
    //===========================================================Modal Politicas==============================================================================================
    v-dialog(v-model='dialog' transition='dialog-bottom-transition' max-width='800')
      template(v-slot:default='dialog')
        v-card
          v-toolbar(color='rgb(255, 87, 0)' dark='') TÉRMINOS Y POLÍTICAS DE DEVOLUCIÓN
          v-card-text
            div(style='padding: 48px 30px 0px 30px !important;').text-h2.black--text La empresa FERCOVEN CA., aceptará la devolución de algún producto siempre y cuando cumpla con todas y cada una de las condiciones que se establecen en la siguiente política de devoluciones:
            div(style='padding: 20px 55px 0px 55px !important;').text-h2.black--text 1.	El plazo máximo para procesar una devolución de producto por: defecto, faltante, incompleto o maltratado será de <b style='text-decoration: underline;'>cuarenta y ocho (48)</b> horas continuas a partir de la fecha de recepción de la factura, sin excepciones. Es importante confirmar el estado físico de los bultos.
            div(style='padding: 20px 55px 0px 55px !important;').text-h2.black--text 2.	Las devoluciones de mercancía <b style='text-decoration: underline;'>FRÁGIL</b> solo procederán cuando la mercancía sea revisada al momento de la entrega (Contra camión) y devuelta en el mismo momento. No procederán reclamos posteriores.
            div(style='padding: 20px 55px 0px 55px !important;').text-h2.black--text 3.	El Producto deberá encontrarse completo, con los sellos intactos, empaques, manuales y accesorio deben ser originales, el empaque o la caja del producto no debe estar rayada, rota, sucia o con otra forma de maltrato o deterioro.
            div(style='padding: 20px 55px 0px 55px !important;').text-h2.black--text 4.	No se aceptan devoluciones por baja rotación de inventario o por error en el Pedido imputable al Cliente.
            div(style='padding: 20px 30px 0px 30px !important;').text-h2.black--text Las Políticas de Devolución no aplican en las siguientes situaciones:
            div(style='padding: 20px 55px 0px 55px !important;').text-h2.black--text 1.	El producto haya sido usado, instalado de forma incorrecta.
            div(style='padding: 20px 55px 0px 55px !important;').text-h2.black--text 2.	El producto fue vendido por una promoción especial o remate.
            div(style='padding: 20px 55px 0px 55px !important;').text-h2.black--text 3.	El producto haya sido recibido bajos características especiales  (lo cual implica aceptación del producto).
            div(style='padding: 20px 30px 0px 30px !important;').text-h2.black--text En caso de proceder la devolución, será reembolsada por medio de una nota de crédito aplicable a una factura actual o futura; y/o cambio por cambio del producto (según existencias) en ningún caso se desembolsará dinero.
          v-card-actions.justify-end
            v-btn(text='' @click='dialog.value = false') Cerrar
    //===========================================================Modal Espera==============================================================================================
    v-dialog(v-model='loading2', persistent max-width='400px' style='background:#f1f2f1')
      v-container(style='max-width: 400px;background:#f1f2f1')
        div(style="margin-top: 20px;")
            v-flex(xs12 sm12)
              v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                v-flex(xs12)
                  v-card-title(style="padding: 7px;").justify-center
                    h4.black--text  Esperando Respuesta del Servidor.!!
                v-flex(xs12)
                    div(style='display: flex;justify-content: center;').text-xs-center
                      img(src='../assets/waiting.gif', with='200' ,height='150')
                v-flex(xs12)
                  v-card-title(style="padding: 7px;").justify-center
                    h5.black--text  Por favor espere...
    Footer
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Api from '@/services/methods'
import { EventBus } from '../Events.js'
import { serverImg } from '@/services/environment'
export default {
  name: 'Carrito',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      car: [],
      checkk: false,
      dialog: false,
      loading2: false,
      mostrarP: false,
      instrucciones: '',
      pantalla: window.innerWidth,
      ruta: serverImg
    }
  },
  created () {
    if (this.$store.state.carFerco !== null) {
      this.car = this.$store.state.carFerco
    }
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb.co_cli) {
      this.mostrarP = true
    }
    this.initialize()
  },
  computed: {
    total () {
      let total = 0
      for (let i = 0; i < this.car.length; i++) {
        total = parseFloat(total) + parseFloat(this.car[i].cant * this.car[i].precio)
      }
      return parseFloat(total.toFixed(2))
    }
  },
  methods: {
    subtotal (item) {
      const total = parseFloat(item.cant) * parseFloat(item.precio)
      return parseFloat(total.toFixed(2))
    },
    revisar () {
      if (this.$store.state.fercoWeb && this.$store.state.fercoWeb._id) {
        if (this.total >= 100) {
          this.$swal
            .fire({
              title: '¿Estás seguro?',
              text: 'No podrás revertir esta operación!',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Si, Continuar!'
            })
            .then(result => {
              if (result.value) {
                let iva = 0
                const art = []
                for (let i = 0; i < this.car.length; i++) {
                  const el = this.car[i]
                  iva += el.cant * el.precio * (el.iva / 100)
                  art.push({
                    ...el,
                    precio: {
                      monto: el.precio,
                      co_precio: el.co_precio
                    }
                  })
                }
                const info = {
                  articulos: art,
                  iva: iva,
                  co_cond: this.$store.state.fercoWeb.co_cond,
                  moneda: 'US$',
                  cliente: {
                    co_cli: this.$store.state.fercoWeb.co_cli,
                    contrib: this.$store.state.fercoWeb.contrib,
                    cli_des: this.$store.state.fercoWeb.cli_des,
                    co_zon: this.$store.state.fercoWeb.co_zon,
                    rif: this.$store.state.fercoWeb.rif
                  },
                  vendedor: {
                    co_ven: this.$store.state.fercoWeb.co_ven,
                    co_us_in: this.$store.state.fercoWeb.co_us_in,
                    sucu_in: this.$store.state.fercoWeb.sucu_in,
                    ven_des: this.$store.state.fercoWeb.ven_des
                  },
                  descrip: 'Pedido web'
                }
                this.loading2 = true
                Api.post('pedido', info)
                  .then(res => {
                    this.loading2 = false
                    this.$swal
                      .fire({
                        title: '¡Felicidades!',
                        text: 'El pedido nro: ' + res.data.doc_num + ' se ha registrado exitosamente.',
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Aceptar'
                      })
                      .then(() => {
                        this.$store.dispatch('vaciarcarrito')
                        this.car = this.$store.state.carFerco
                        EventBus.$emit('actCarrito')
                        this.$router.push('/')
                      })
                  })
                  .catch(err => {
                    this.loading2 = false
                    console.error(err)
                  })
              }
            })
        } else {
          this.$swal.fire(
            '¡Atención!',
            'Estimado usuario, la compra debe tener un minimo de 100$ para poder realizarla.',
            'info'
          )
        }
      } else {
        this.$router.push('/ingreso')
      }
    },
    restar (item) {
      const info = {
        ...item
      }
      if (info.cant) {
        const vald = parseFloat(info.cant) - parseFloat(info.equivalente)
        if (vald >= 0) {
          info.cant = parseFloat(info.cant) - parseFloat(info.equivalente)
        }
      } else {
        info.cant = 0
      }
      this.$store.dispatch('modificarcarrito', info)
    },
    disponibilidad (item, unidad) {
      let equi
      for (let i = 0; i < item.unidades.length; i++) {
        if (unidad === item.unidades[i]) {
          equi = i
        }
      }
      if (equi === 0) {
        return item.stock
      } else {
        return Math.round(item.stock * item.equivalencia[equi])
      }
    },
    sumar (item) {
      // let prod = {}
      // for (let i = 0; i < this.car.length; i++) {
      //   if (this.car[i]._id === item._id) {
      //     prod = this.car[i]
      //   }
      // }
      // if ((item.cant + 1) <= item.stock) {
      //   this.$store.dispatch('sumar', item)
      // }
      const vald = parseFloat(item.cant) + parseFloat(item.equivalente)
      if (vald <= Math.trunc(this.disponibilidad(item, item.unidad))) {
        const info = {
          ...item
        }
        info.cant = vald
        this.$store.dispatch('sumar', info)
      }
    },
    limpiar () {
      this.$store.dispatch('vaciarcarrito')
      this.car = this.$store.state.carFerco
      EventBus.$emit('vaciar')
    },
    borrar (item) {
      this.$store.dispatch('eliminar', item)
      this.car = this.$store.state.carFerco
    },
    initialize () {
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/carrito.scss";
</style>
