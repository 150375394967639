import axios from 'axios'
import { server, port } from './environment'

export default () => {
  return axios.create({
    baseURL: `${server}:${port}/api/`,
    headers: {
      'authorization' : 'Bearer ' + $cookies.get('token'),
      'uuid': $cookies.get('uuid')
    }
  })
}
