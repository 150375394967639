<template lang="pug">
  #Ingreso
    Header
    div(style='background-color: gainsboro;height: 60px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 style='display: flex;justify-content: flex-start;align-items: center;')
            a(style='font:size:14px;font-weight: bold;color: #FF6200;text-decoration: initial;' href='/') Inicio
            span(style='padding-left:10px;') /
            span(style='font:size:14px;padding-left:10px;font-weight: bold;') Cuenta
    div(style='height: auto;display: flex;justify-content: center;align-items: center;')
      v-flex(xs10)
        v-layout(wrap style='margin-top:70px;')
            v-flex(xs12 sm6 style='margin-bottom:60px;padding:15px')
                h2(style='margin-bottom:10px;') RESTABLECER SU CONTRASEÑA
                v-card(style='border:1px solid #eeeeee;padding:30px;height: 90%;').elevation-0
                    div
                        span(style='font-weight:500;') Correo Electrónico
                        v-text-field(outlined v-model='form.email' placeholder='Correo electrónico' color='#878787')
                    div(style='display:flex;flex-direction:column;align-items: baseline;')
                        v-btn(style='background:#ff5700;color:white;border-radius:0px;' @click='enviar()').elevation-0 ENVIAR
            v-flex(xs12 sm6 style='margin-bottom:60px;padding:15px')
                h2(style='margin-bottom:10px;') NUEVO CLIENTE
                v-card(style='border:1px solid #eeeeee;padding:30px;height: 90%;').elevation-0
                    div(style='margin-bottom:15px;')
                        span(style='font-weight:500;font-size:15px;') CREAR UNA CUENTA
                    div(style='line-height: 1.7;')
                        span(style='font-weight:400;font-size:14px;') Regístrese para obtener una cuenta gratuita en nuestra tienda. Registrarse es facil y rapido. Le permite realizar pedidos en nuestra tienda. Para comenzar a comprar, haga click en registrarse.
                    div(style='display:flex;flex-direction:column;align-items: baseline;margin-top:15px;')
                        v-btn(style='background:#ff5700;color:white;border-radius:0px;' to='/registrarse').elevation-0 registrarse
    Footer
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Api from '@/services/methods'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { mapState, mapActions } from 'vuex'
import { serverImg } from '@/services/environment'
export default {
  name: 'Recuperar',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      form: {
        email: ''
      },
      pantalla: screen.width,
      ruta: serverImg
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    enviar () {
      if (this.form.email) {
        Api.post('clientesWeb/recuperacion', this.form)
          .then(res => {
            if (res.data.message) {
              Swal.fire(
                'Error encontrado',
                res.data.message,
                'error'
              )
            } else {
              Swal.fire(
                '¡Felicidades!',
                'Su solicitud fue procesada con éxito, en unos minutos estará recibiendo un correo electrónico.',
                'success'
              )
              this.form.email = ''
              console.log(res.data)
            }
          })
          .catch(error => {
            Swal.fire(
              'Oops...',
              'Error de conexión, intente nuevamente o contacte con el administrador.',
              'error'
            )
            console.log(error)
          })
      } else {
        this.showAlert()
      }
    },
    showAlert () {
      Swal.fire({
        title: 'Error encontrado.',
        text: 'Estimado cliente, debe ingresar el email para continuar.',
        icon: 'error'
      })
    },
    initialize () {
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/ingreso.scss";
</style>
