<template lang="pug">
  #Footer
    div(style="background: white;display: flex;justify-content: center;margin-bottom:60px;margin-top:30px;")
      v-flex(xs11 md11 sm11)
        v-layout(wrap)
          v-flex(xs12 sm12 md5 style='display: flex;flex-direction: column;')
            h2 Manténte al día!
            div
              img(src='@/assets/imgFerco/logo2.png' class='imgFot'  @click='irHome()')
            span(v-if='settings.direccion' style='font-weight: 300;font-size: 14px;') {{settings.direccion}}
            div(style='width:400px;')
              v-skeleton-loader.mx-auto#ld2(v-if='policeSett' max-height='30' max-width='400' type='image')
            div(style='display:flex;margin-top:25px;align-items: center;')
              img(v-if='settings.whatsapp' src='@/assets/imgFerco/whatsapp.png' style='height:30px;width:auto;object-fit:contain;')
              a(v-if='settings.whatsapp' style='margin-left:10px;font-weight: 300;font-size: 14px;' :href='"https://wa.me/"+settings.whatsapp' target='_blank') {{settings.whatsapp}}
              div(style='width:100px;padding-left:10px;')
                v-skeleton-loader.mx-auto#ld2(v-if='policeSett' max-height='30' max-width='100' type='image')
          v-flex(xs12 sm12 md7 style='justify-content: end;')
            div(style='display:flex;width: 100%;margin-top:20px;justify-content: end;')
              div(style='padding-right: 20%;')
                h3.mb-5 Categorías
                p(style='cursor:pointer' v-for='item in categorias' @click='irProductos(item)') {{item.subl_des}}
                div(v-for='item in catArry' style='padding-bottom:10px;')
                  v-skeleton-loader.mx-auto#ld2(v-if='policeCate' max-height='30' max-width='200' type='image')
              div(style='padding-right: 5%;')
                h3.mb-5 Links de interés
                p(style='cursor:pointer' @click='irContacto()') Contáctenos
                p(style='cursor:pointer' @click='irAcerca()') Acerca de nosotros
                a(v-if='settings && settings.instagram' style='cursor:pointer' :href='"https://www.instagram.com/"+settings.instagram' target='_blank') {{settings.instagram}}

</template>
<script>
import { serverImg } from '@/services/environment'
import { EventBus } from '../Events.js'
import Api from '@/services/methods'
export default {
  data () {
    return {
      settings: {},
      mostrarP: false,
      policeCate: true,
      policeSett: true,
      catalogo: '',
      categorias: [],
      catArry: ['1', '2', '3', '4'],
      ruta: serverImg
    }
  },
  created () {
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb.co_cli) {
      this.mostrarP = true
    }
    this.initialize()
  },
  methods: {
    // verificar() {
    //   this.$nextTick(() => {
    //     $("#sobre").trigger("click");
    //   })
    //   return this.$route.name
    // },
    // ir() {
    //   this.$router.push({name: 'Home', params: { value: 'other' }})
    // },
    irHome () {
      this.$router.push('/')
    },
    irContacto () {
      this.$router.push('/contacto')
    },
    irAcerca () {
      this.$router.push('/acerca')
    },
    irProductos (item) {
      if (this.$route.name == 'Productos') {
        EventBus.$emit('buscarCategoria', item)
      } else {
        this.$router.push({ name: 'Productos', params: { categoria: item } })
      }
    },
    initialize () {
      this.policeSett = true
      Api.get('categoriaWeb/footer').then(res => {
        this.categorias = res.data
        if (res.data && res.data.length > 0) this.policeCate = false
      })
      Api.get('settings').then(res => {
        this.policeSett = false
        if (res.data && res.data[0]) {
          this.settings = res.data[0]
        }
      })
      // Api.get('settings/catalogo').then(res => {
      //   this.catalogo = res.data
      //   console.log(this.catalogo)
      // })
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/footer.scss";
</style>
