<template lang="pug">
  #Ingreso
    Header
    div(style='background-color: gainsboro;height: 60px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 style='display: flex;justify-content: flex-start;align-items: center;')
            a(style='font:size:14px;font-weight: bold;color: #FF6200;text-decoration: initial;' href='/') Inicio
            span(style='padding-left:10px;') /
            span(style='font:size:14px;padding-left:10px;font-weight: bold;') Contacto
    div(style='height: auto;display: flex;justify-content: center;align-items: center;')
      v-flex(xs12)
        v-layout(wrap style='margin-bottom:40px;')
          v-flex(xs12 v-if='police')
            v-skeleton-loader.mx-auto#ld4(style='width:100%;' type='image')
          v-flex(xs12 v-if='contacto && contacto.coordenadas')
            GmapMap(v-if='contacto && contacto.coordenadas' :center='contacto.coordenadas', :zoom='zooom', style='width: 100%; height: 500px')
                GmapMarker(:position='contacto.coordenadas', :clickable='true', :draggable='true')
    div(style='height: auto;display: flex;justify-content: center;align-items: center;')
      v-flex(xs10 sm10 md10)
        v-layout(wrap style='margin-top:70px;margin-bottom:40px;display: flex;align-items: center;')
          v-flex(xs12 style='text-align:center;margin-bottom:40px;')
            h1(style='font-weight: 900;') ¡Contáctanos!
          v-flex(xs12 sm12 md4 v-if='police').centradoAll
            v-skeleton-loader.mx-auto#ld5(style='width:200px' type='image')
          v-flex(xs12 sm12 md4 v-if='contacto && contacto.correo && contacto.correo.length > 0').centradoAll
            h3(style='font-weight: 900;margin-bottom:20px;') Contacto directo
            div( style='display: flex;flex-direction: column;font-weight: 600;opacity: 0.6;text-align:center;')
              a(v-for='item in contacto.correo') {{item}}
          v-flex(xs12 sm12 md4 v-if='police').centradoAll
            v-skeleton-loader.mx-auto#ld5(style='width:200px' type='image')
          v-flex(xs12 sm12 md4 v-if='contacto && contacto.direccion').centradoAll
            h3(style='font-weight: 900;margin-bottom:20px;') Dirección
            span(style='font-weight: 600;opacity: 0.6;') {{contacto.direccion}}
          v-flex(xs12 sm12 md4 v-if='police').centradoAll
            v-skeleton-loader.mx-auto#ld5(style='width:200px' type='image')
          v-flex(xs12 sm12 md4 v-if='contacto && contacto.telefonos && contacto.telefonos.length > 0').centradoAll
            h3(style='font-weight: 900;margin-bottom:20px;') Teléfonos
            div( style='display: flex;flex-direction: column;font-weight: 600;opacity: 0.6;text-align:center;')
              span(v-for='item in contacto.telefonos') {{item}}
    div(style='height: auto;display: flex;justify-content: center;align-items: center;background:#f8f8f8;')
      v-flex(xs10 sm10 md10)
        v-layout(wrap style='margin-top:70px;margin-bottom:40px;')
            v-flex(xs6 style='padding:5px;')
                v-text-field(solo v-model='form.nombre' placeholder='Nombre' color='#878787')
            v-flex(xs6 style='padding:5px;')
                v-text-field(solo v-model='form.correo' placeholder='Correo electrónico' color='#878787')
            v-flex(xs12 style='padding:5px;')
                v-text-field(solo v-model='form.telefono' placeholder='Número de teléfono' color='#878787')
            v-flex(xs12 style='padding:5px;')
                v-textarea(solo v-model='form.mensaje' placeholder='Mensaje' color='#878787')
            v-flex(xs12 style='padding:5px;')
                v-btn(style='background:#ff5700;color:white;border-radius:0px;' @click='enviar()').elevation-0 Enviar
    Footer
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Api from '@/services/methods'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { serverImg } from '@/services/environment'
export default {
  name: 'Contacto',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      centrarmapa: { lat: 18.735693, lng: -70.1626511 },
      police: true,
      zooom: 14,
      form: {
        nombre: '',
        correo: '',
        telefono: '',
        mensaje: '',
        estatus: 'Pendiente'
      },
      defaultForm: {
        nombre: '',
        correo: '',
        telefono: '',
        mensaje: '',
        estatus: 'Pendiente'
      },
      contacto: {},
      pantalla: screen.width,
      ruta: serverImg
    }
  },
  created () {
    document.documentElement.scrollTop = 0
    this.initialize()
  },
  methods: {
    initialize () {
      this.police = true
      Api.get('contacto').then(res => {
        this.contacto = res.data[0]
        this.police = false
      })
    },
    enviar () {
      if (this.form.nombre && this.form.correo && this.form.telefono && this.form.mensaje) {
        Api.post('mensaje', this.form).then(res => {
          Swal.fire({
            title: '¡Felicidades!',
            text: 'Su mensaje se ha enviado exitosamente.',
            icon: 'success'
          })
          this.form = Object.assign({}, this.defaultForm)
        }).catch(err => {
          console.log(err)
          Swal.fire(
            'Error inesperado',
            'Ha ocurrido un error, por favor verifique la información e intente de nuevo.',
            'error'
          )
        })
      } else {
        Swal.fire({
          title: 'Error encontrado.',
          text: 'Estimado cliente, debe ingresar todos los datos solicitados para poder enviar el mensaje.',
          icon: 'error'
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/ingreso.scss";
.centradoAll {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 960px) {
  .centradoAll {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top:20px;
  }
}
</style>
