<template lang="pug">
  #Productos
    Header
    div(style='background-color: gainsboro;height: 60px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 style='display: flex;justify-content: flex-start;align-items: center;')
            a(style='font:size:14px;font-weight: bold;color: #FF6200;text-decoration: initial;' href='/') Inicio
            span(style='padding-left:10px;') /
            span(style='font:size:14px;padding-left:10px;font-weight: bold;') Detalle
    div(style='background-color: #FF6200;height: 150px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs12)
        v-layout(wrap)
          v-flex(xs12 v-if='policeDepart')
            carousel(:perPageCustom="[[320, 1],[480, 2], [768, 4], [1160, 8]]")
              slide(v-for='(item,i) in arraySke' style='padding-left: 5px;padding-right: 5px;')
                div.carouselMarkSke
                  v-skeleton-loader.mx-auto#ld6(style='width:100%;' type='image')
          v-flex(xs12 v-if='departamentos && departamentos.length > 0')
            carousel(:perPageCustom="[[320, 1],[480, 2], [768, 4], [1160, 8]]")
              slide(v-for='(item,i) in departamentos' style='padding-left: 5px;padding-right: 5px;text-align:center;').centrarAll
                div(@click='irProductos(item)' style='cursor:pointer;').carouselMarkSke
                  img(:src='ruta + item.imagen' style='height:50px;width:auto;object-fit:contain;')
                  span(style='color:white;font-size:11px;margin-top:20px;') {{item.lin_des}}
    div(style='display:flex;justify-content:center;margin-top:60px;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 sm12 md3)
            div(style='margin-bottom:40px;')
              v-card(color='#eaeaea' style="border-radius: 0px;padding-left: 20px;").elevation-0
                v-card-text
                  div(style='display: flex;align-items: center;margin-bottom: 20px;')
                    img(src='@/assets/imgFerco/papel.png' style='height:50px;width:auto;object-fit:contain;')
                    div(style='display: flex;flex-direction: column;padding-left: 15px;')
                      span(style='font-size: 15px;font-weight: bold;') Mayor ferretero
                      span(style='font-size: 13px;font-weight: 300;') gran variedad de stock
                  div(style='display: flex;align-items: center;margin-bottom: 20px;')
                    img(src='@/assets/imgFerco/people.png' style='height:50px;width:auto;object-fit:contain;')
                    div(style='display: flex;flex-direction: column;padding-left: 15px;')
                      span(style='font-size: 15px;font-weight: bold;') Equipo comprometido
                      span(style='font-size: 13px;font-weight: 300;') con nuestros clientes
                  div(style='display: flex;align-items: center;margin-bottom: 20px;')
                    img(src='@/assets/imgFerco/camion.png' style='height:50px;width:auto;object-fit:contain;')
                    div(style='display: flex;flex-direction: column;padding-left: 15px;')
                      span(style='font-size: 15px;font-weight: bold;') Entregas
                      span(style='font-size: 13px;font-weight: 300;') inmediatas y seguras
                  div(style='display: flex;align-items: center;margin-bottom: 20px;')
                    img(src='@/assets/imgFerco/tienda.png' style='height:50px;width:auto;object-fit:contain;')
                    div(style='display: flex;flex-direction: column;padding-left: 15px;')
                      span(style='font-size: 15px;font-weight: bold;') Llegamos hasta
                      span(style='font-size: 13px;font-weight: 300;') cada abastecimiento
          v-flex(xs12 sm12 md1)
          v-flex(xs12 sm12 md1)
            div(v-if='producto && producto.imagen && producto.imagen.length > 0')
              div(v-for='(img, i) in producto.imagen' @click='seleccionarImg(img, i)' v-bind:class="{ divImgAc: index == i, divImgInc: index != i }")
                img(:src='ruta + img.url' style='width:100%;height:100px;object-fit:contain;')
          v-flex(xs12 sm12 md7)
            div(v-if='mostrarMensaje' style='width:100%;margin-left:20px;height:40px;background:#eaeaea;margin-bottom:20px;display:flex;justify-content:space-between;padding: 0px 15px 0px 15px;')
              div(style='display:flex;justify-content:center;align-items:center')
                div(style='background:green;display:flex;justify-content:center;align-items:center;width:32px;height:32px;border-radius:100%;')
                  img(src='@/assets/imgFerco/check.png' style='width:20px;height:20px;object-fit:contain;')
                span(style='margin-left:10px;') Su produto se ha añadido a tu carrito exitosamente.
            v-layout(wrap v-if='policeProd')
              v-flex(xs12 sm12 md6)
                div(style='position:relative;')
                  v-skeleton-loader.mx-auto#ld7(style='width:100%;' type='image')
              v-flex(xs12 sm12 md6)
                div(style='margin-top:10px;')
                  v-skeleton-loader#ld8(style='width:100%;' type='image')
                  v-skeleton-loader#ld8(style='width:200px;margin-top:10px;' type='image')
                  v-skeleton-loader#ld8(style='width:200px;margin-top:10px;' type='image')
                  v-skeleton-loader#ld8(style='width:200px;margin-top:10px;' type='image')
                  v-skeleton-loader#ld8(style='width:200px;margin-top:10px;' type='image')
                  v-skeleton-loader#ld9(style='width:100%;margin-top:10px;' type='image')
            v-layout(wrap v-if='producto')
              v-flex(xs12 sm12 md6)
                div(style='position:relative;')
                  img(v-if='producto && producto.imagen && producto.imagen.length > 0' :src='ruta + seleccion.url' style='width:100%;height:300px;object-fit:contain;')
                  img(v-else src='@/assets/imgFerco/image.png' style='width:100%;height:300px;object-fit:contain;')
              v-flex(xs12 sm12 md6)
                h2 {{producto.art_des}}
                div(style='display:flex;flex-direction:column;margin-top:10px;')
                  span(style='font-weight:bold;') {{producto.co_art}}
                  span(style='margin-top:5px;') {{producto.categoriaN}}
                  span(style='margin-top:5px;') {{producto.marcaN}}
                  div(style='margin-top:5px;' v-if='mostrarS')
                    span Existencia:
                    span(v-if='disponibilidad(producto, producto.unidad) && disponibilidad(producto, producto.unidad) > 0' style='color:green;padding-left:4px;') {{disponibilidad(producto, producto.unidad)}}
                    span(v-if='!disponibilidad(producto, producto.unidad) || disponibilidad(producto, producto.unidad) <= 0' style='color:red;padding-left:4px;') 0
                  span(v-if='producto.descripcion' style='margin-top:10px;font-size:14px;color: #5c5b5b;') {{producto.descripcion}}
                  h1(v-if='mostrarP' style='color:red;margin-top:20px;') ${{producto.precio}}
                v-layout(v-if='(producto.presentacion1 && producto.presentacion1.id) || (producto.presentacion2 && producto.presentacion2.id)' wrap style='margin-top:10px;')
                  v-flex(v-if='producto.presentacion1  && producto.presentacion1.id' xs12 style='display:flex;flex-direction:column;')
                    h4 {{producto.presentacion1.id.titulo}}
                    div(style='display:flex;margin-top:10px;flex-wrap: wrap;')
                      div(v-for='item in presentaciones1' v-bind:class="{ selectPreIn: producto.presentacion1.valor == item.valor, selectPre: producto.presentacion1.valor != item.valor }" @click="seleccionarPre(item)")
                        span {{item.valor}}
                        span(v-if='item.stock && item.stock > 0' class='btnStock') Stock: {{item.stock}}
                        span(v-else class='btnStock') Sin stock
                  v-flex(v-if='producto.presentacion2 && producto.presentacion2.id' xs12 style='display:flex;flex-direction:column;margin-top:20px;')
                    h4 {{producto.presentacion2.id.titulo}}
                    div(style='display:flex;margin-top:10px;')
                      div(v-for='item in presentaciones2'  v-bind:class="{ selectPreIn: producto.presentacion2.valor == item.valor, selectPre: producto.presentacion2.valor != item.valor }" @click="seleccionarPre(item)")
                        span {{item.valor}}
                        span(v-if='item.stock && item.stock > 0' class='btnStock') Stock: {{item.stock}}
                        span(v-else class='btnStock') Sin stock
                v-layout(v-if='!producto.stock || producto.stock <= 0' wrap style='margin-top:30px;')
                  v-flex(xs12 style='display:flex;justify-content:center;')
                    div.divStock
                      span Sin stock
                v-layout(v-if='producto.stock && producto.stock > 0' wrap style='margin-top:30px;')
                  v-flex#inpp(xs12 sm12 md12 lg6 style='display:flex;')
                    div(style='display:flex;')
                      v-btn(@click='restar()' style='min-width: 40px;width: 50px;background:#0F4DFF;height: 40px;border-radius: 5px 0px 0px 5px;margin-right: 5px;').elevation-0
                        img(src='@/assets/imgFerco/-blanco.png' style='height:16px;width:16px;object-fit:contain;')
                      v-text-field(v-model='cantidad' type='number' min='1' :max='producto.stock' hide-details='' filled='' dark='' dense='' :hide-spin-buttons='true' background-color='#0F4DFF' style='border-radius: 0 !important;width:90px;').elevation-0
                      v-btn(@click='sumar()' style='min-width: 40px;width: 50px;background:#0F4DFF;height: 40px;border-radius: 0px 5px 5px 0px;margin-left: 5px;').elevation-0
                        img(src='@/assets/imgFerco/+blanco.png' style='height:16px;width:16px;object-fit:contain;')
                  v-flex(xs12 sm12 md12 lg6 class='mgtt')
                    v-btn(@click='añadircarrito()' class='btnAgg').elevation-0
                      | Agregar al carrito
    div(style='display:flex;justify-content:center;margin-top:40px;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 sm12 md4)
          v-flex(xs12 sm12 md8)
            div
              v-flex(xs12, sm12, md12)
                v-layout(v-if='policeProd' wrap style='margin-top:20px;')
                  v-flex(xs12='', md4='', sm12='', v-for='(item, index) in arrayPro' style='padding:10px;margin-bottom:40px;')
                    v-skeleton-loader.mx-auto#ld7(style='width:100%;' type='image')
                v-layout(v-if='similares && similares.length > 0' wrap style='margin-top:20px;')
                  v-flex(xs12)
                    h2(style='padding-left:10px;') Productos similares
                  v-flex(xs12='', md4='', sm12='', v-for='(item, index) in similares' style='padding:10px;margin-bottom:40px;')
                    template
                      a(@click='ir(item._id)').my-1
                        v-card(class='cardPro' style='height:100%;').mx-auto.marg.elevation-0
                          div(style='width: 100%;height: 270px;position:relative;display:flex;justify-content:center;align-items:center;')
                            img(v-if='item.imagen && item.imagen[0]' :src='ruta + item.imagen[0].url' style='width: 200px;height: 200px;object-fit: contain;')
                            img(v-else src='@/assets/imgFerco/image.png' style='width: 200px;height: 200px;object-fit: contain;')
                          div(style='display:flex;justify-content:center;')
                            div(style='width:80%;height:1px;background:lightgrey;')
                          div(style='margin-top: 10px;display:flex;flex-direction:column;')
                            span(style='font-size: 15px;font-weight: 800;color: grey;') {{item.art_des}}
                            span(style='font-size: 14px;font-weight: 500;color: grey;margin-top: 5px;') {{item.marcaN}}
                          div(v-if='mostrarS')
                            span(v-if='item.stock && item.stock > 0' style='font-size: 14px;font-weight: 500;color: grey;margin-top: 5px;') Existencia: {{item.stock}}
                            span(v-else style='font-size: 14px;font-weight: 500;color: white;margin-top: 5px;background: red;padding: 1px 10px 1px 10px;border-radius: 40px;') Sin stock
                          div( v-if='mostrarP' style='padding-bottom:20px;')
                              span(style='font-size: 16px;font-weight: bold;color:red;') ${{item.precio}}
    Footer
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Api from '@/services/methods'
import { EventBus } from '../Events.js'
import { serverImg } from '@/services/environment'
export default {
  name: 'Detalle',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      arraySke: ['1', '2', '3', '4', '5', '6', '7', '8'],
      arrayPro: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      policeDepart: true,
      policeProd: true,
      seleccion: null,
      index: null,
      mostrarMensaje: false,
      departamentos: [],
      presentaciones1: [],
      presentaciones2: [],
      producto: null,
      ruta: serverImg,
      mostrarP: false,
      mostrarS: false,
      cantidad: 0,
      similares: [],
      grupos: [],
      car: []
    }
  },
  watch: {
    // cantidad(val) {
    //   if (val && val < 1) {
    //     this.$nextTick(() => {
    //       this.cantidad = 1
    //     })
    //   }
    //   if (val && this.producto && val > parseFloat(this.producto.stock)) {
    //     this.$nextTick(() => {
    //       this.cantidad = parseFloat(this.producto.stock)
    //     })
    //   }
    // }
  },
  mounted () {
    EventBus.$on('buscarDetalle', async data => {
      this.policeProd = true
      await this.obtenerProducto(data)
    })
  },
  async created () {
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb.co_cli) {
      this.mostrarP = true
    }
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb.stock) {
      this.mostrarS = this.$store.state.fercoWeb.stock
    }
    if (this.$store.state.carFerco !== null) {
      this.car = this.$store.state.carFerco
    }
    if (this.$route.params && this.$route.params.value) this.producto = this.$route.params.value
    document.documentElement.scrollTop = 0
    await this.initialize()
  },
  methods: {
    ir (item) {
      this.$router.push('/detalle/' + item)
      this.obtenerProducto(item)
      document.documentElement.scrollTop = 0
    },
    seleccionarImg (img, i) {
      this.seleccion = img
      this.index = i
    },
    showAlert () {
      Swal.fire({
        position: 'top-start',
        icon: 'error',
        title: 'Ha superado el limite de productos con respecto a la existencia del mismo.',
        showConfirmButton: false,
        timer: 1500
      })
    },
    validarCrdito () {
      let tot = 0
      this.car.forEach(el => {
        tot += parseFloat(el.cant) * parseFloat(el.precio)
      })
      console.log(tot)
      if (tot > parseFloat(this.$store.state.fercoWeb.mont_cre)) {
        this.$swal.fire({
          position: 'top-end',
          icon: 'info',
          title: 'Estimado cliente, ha sobrepasado el límite de crédito',
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    añadircarrito () {
      if (this.cantidad > 0) {
        // this.validarCrdito()
        if (this.car && this.car.length >= 25) {
          this.$swal.fire({
            position: 'top-end',
            icon: 'info',
            title: 'Estimado cliente, ha sobrepasado el límite de productos por pedido.',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          let check = false
          const info = {
            ...this.producto,
            cant: this.cantidad
          }
          for (let i = 0; i < this.car.length; i++) {
            if (info._id === this.car[i]._id) {
              if ((parseInt(this.car[i].cant) + info.cant) > parseInt(this.car[i].stock)) {
                check = true
                this.showAlert()
              } else {
                this.$store.dispatch('agregarcarrito2', info)
                EventBus.$emit('actCarrito')
                check = true
                this.mostrarMensaje = true
                setTimeout(function () { this.mostrarMensaje = false }.bind(this), 3000)
                this.cantidad = 0
                this.car = this.$store.state.carFerco
              }
            }
          }
          if (check === false) {
            this.$store.dispatch('agregarcarrito', info)
            EventBus.$emit('actCarrito')
            this.car = this.$store.state.carFerco
            this.mostrarMensaje = true
            setTimeout(function () { this.mostrarMensaje = false }.bind(this), 3000)
            this.cantidad = 0
          }
        }
      } else {
        this.$swal.fire({
          position: 'top-start',
          icon: 'error',
          title: 'La cantidad debe ser mayor a 0 para poder añadirlo al carrito.',
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    disponibilidad (item, unidad) {
      let equi
      for (let i = 0; i < item.unidades.length; i++) {
        if (unidad === item.unidades[i]) {
          equi = i
        }
      }
      if (equi === 0) {
        return item.stock
      } else {
        return Math.round(item.stock * item.equivalencia[equi])
      }
    },
    sumar () {
      if (this.cantidad) {
        const vald = parseFloat(this.cantidad) + parseFloat(this.producto.equivalente)
        if (vald <= Math.trunc(this.disponibilidad(this.producto, this.producto.unidad))) {
          this.cantidad = parseFloat(this.cantidad) + parseFloat(this.producto.equivalente)
        }
      } else {
        const vald = parseFloat(this.cantidad) + parseFloat(this.producto.equivalente)
        if (vald <= Math.trunc(this.disponibilidad(this.producto, this.producto.unidad))) {
          this.cantidad = parseFloat(this.producto.equivalente)
        } else {
          this.$swal.fire({
            position: 'top-start',
            icon: 'error',
            title: 'Este producto no esta disponible en esta unidad',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      // if ((this.cantidad+1) > parseFloat(this.producto.stock)) {
      //   this.cantidad = parseFloat(this.producto.stock)
      // } else this.cantidad++
    },
    restar () {
      if (this.cantidad) {
        const vald = parseFloat(this.cantidad) - parseFloat(this.producto.equivalente)
        if (vald >= 0) {
          this.cantidad = parseFloat(this.cantidad) - parseFloat(this.producto.equivalente)
        }
      } else {
        this.cantidad = 0
      }
      // if ((this.cantidad-1) < 1) {
      //   this.cantidad = 1
      // } else this.cantidad--
    },
    irProductos (item) {
      this.$router.push({ name: 'Productos', params: { depart: item } })
    },
    async seleccionarPre (item) {
      // let id
      // for (let i = 0; i < this.grupos.length; i++) {
      //   const el = this.grupos[i];
      //   if (el.presentacion1 && el.presentacion1.id && el.presentacion1.id == item.id && el.presentacion1.valor == item.valor) id = el._id
      //   if (el.presentacion2 && el.presentacion2.id && el.presentacion2.id == item.id && el.presentacion2.valor == item.valor) id = el._id
      // }
      // await this.obtenerProducto(id)
      for (let i = 0; i < this.grupos.length; i++) {
        const el = this.grupos[i]
        if (el.presentacion1 && el.presentacion1.id && el.presentacion1.id == item.id && el.presentacion1.valor == item.valor) this.producto = el
        if (el.presentacion2 && el.presentacion2.id && el.presentacion2.id == item.id && el.presentacion2.valor == item.valor) this.producto = el
      }
      if (this.producto.imagen && this.producto.imagen[0]) {
        this.seleccion = this.producto.imagen[0]
        this.index = 0
      }
    },
    obtenerProducto (code) {
      const cli = (this.$store.state.fercoWeb && this.$store.state.fercoWeb.co_precio) ? this.$store.state.fercoWeb.co_precio.trim() : null
      Api.get('productosCom/detailWeb?id=' + code + '&codigo=' + cli).then(res => {
        this.policeProd = false
        this.producto = res.data.producto
        this.producto.unidad = (res.data.producto.unidades[1]) ? res.data.producto.unidades[1] : res.data.producto.unidades[0],
        this.producto.equivalente = (res.data.producto.equivalencia[1]) ? res.data.producto.equivalencia[1] : res.data.producto.equivalencia[0],
        this.similares = res.data.similares
        this.grupos = res.data.grupos
        this.presentaciones1 = []
        this.presentaciones2 = []
        if (this.producto.presentacion1 && this.producto.presentacion1.id) this.presentaciones1.push({ id: this.producto.presentacion1.id._id, valor: this.producto.presentacion1.valor, stock: this.producto.stock })
        if (this.producto.presentacion2 && this.producto.presentacion2.id) this.presentaciones2.push({ id: this.producto.presentacion2.id._id, valor: this.producto.presentacion2.valor, stock: this.producto.stock })
        if (this.grupos && this.grupos.length > 0) {
          for (let i = 0; i < this.grupos.length; i++) {
            const el = this.grupos[i]
            if (this.producto.presentacion1 && this.producto.presentacion1.id) {
              if (el.presentacion1 && el.presentacion1.id && el.presentacion1.id == this.producto.presentacion1.id._id) {
                let bool = false
                for (let j = 0; j < this.presentaciones1.length; j++) {
                  const el2 = this.presentaciones1[j]
                  if (el.presentacion1.valor == el2.valor) bool = true
                }
                if (!bool) this.presentaciones1.push({ id: el.presentacion1.id, valor: el.presentacion1.valor, stock: el.stock })
              }
            }
            if (this.producto.presentacion2 && this.producto.presentacion2.id) {
              if (el.presentacion2 && el.presentacion2.id && el.presentacion2.id == this.producto.presentacion2.id._id) {
                let bool = false
                for (let j = 0; j < this.presentaciones2.length; j++) {
                  const el2 = this.presentaciones2[j]
                  if (el.presentacion2.valor == el2.valor) bool = true
                }
                if (!bool) this.presentaciones2.push({ id: el.presentacion2.id, valor: el.presentacion2.valor, stock: el.stock })
              }
            }
          }
        }
        if (this.presentaciones1.length > 0) this.presentaciones1.sort(function (a, b) { return a.valor - b.valor })
        if (this.presentaciones2.length > 0) this.presentaciones2.sort(function (a, b) { return a.valor - b.valor })
        if (this.producto.imagen && this.producto.imagen[0]) {
          this.seleccion = this.producto.imagen[0]
          this.index = 0
        }
      })
    },
    async initialize () {
      this.policeDepart = true
      this.policeProd = true
      Api.get('departamentos/withImg').then(res => {
        this.departamentos = res.data
        this.policeDepart = false
      })
      const arry = this.$route.path.split('/')
      await this.obtenerProducto(arry[2])
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/productos.scss";
#inpp {
  .v-input input {
    text-align: center !important;
  }
}
.divStock {
  width: 150px;
  background: red;
  padding: 10px;
  color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}
</style>
