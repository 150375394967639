<template lang="pug">
  #Ingreso
    Header
    div(style='background-color: rgb(250, 250, 250);height: 100px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs10)
        v-layout(wrap)
          v-flex(xs6)
            span(style='font-weight: 500;opacity: 0.8;') Resultados de la busqueda
          v-flex(xs6 style='display: flex;justify-content: flex-end;')
            a(style='font-weight: bold;color:black;text-decoration: initial;' href='/') INICIO
            span(style='padding-left:10px;') /
            span(style='padding-left:10px;font-weight: bold;opacity: 0.6;text-transform: uppercase;') RESULTADOS DE LA BUSQUEDA
    div(style='height: auto;display: flex;justify-content: center;align-items: center;')
      v-flex(xs10 sm10 md10)
        v-layout(wrap style='margin-top:70px;')
          template
            v-flex(xs12 style='text-align: center;display: flex;justify-content: center;align-items: center;flex-direction: column;margin-bottom: 20px;')
                span(style='font-size: 32px;font-weight: 500;') Busqueda
                div(style='height:2px;width:70px;background:#878787;')
            v-flex(xs12 style='display:flex;justify-content:center;')
              div(style='width:60%;display: flex;')
                v-text-field(outlined dense v-model='search' placeholder='Buscar Productos' style='width:100%;' @keydown.enter="teclado")
                v-btn(style='background:#ff5700;color:white;height: 41px;margin-left:2px;' @click='enviar()').elevation-0 enviar
            v-flex(xs12 style='text-align: center;display: flex;justify-content: center;align-items: center;margin-bottom: 20px;')
              v-layout(wrap)
                v-flex(xs1 v-if='categorias.length > 0' style='display: flex;justify-content: center;align-items: center;z-index: 10;').pl
                  v-btn(style='height: 40px;width: 40px;min-width: 40px;background-color: transparent;color: black;border-radius: 100%;' @click="SlideCarousel('prev')").elevation-0
                    v-icon( color='black' style='font-size:22px;') keyboard_arrow_left
                v-flex(xs10 v-if='categorias.length > 0')
                  carousel(ref="productDetails" :perPageCustom="[[300, 1], [500, 2], [700, 3], [900, 4], [1200, 5]]" :mouseDrag='false' :centerMode='true')
                    slide(v-for='(item,i) in categorias', style='display: flex;justify-content: center;flex-direction: column;text-align:center;padding: 15px;')
                      v-btn(:id='"btnn"+i' @click='seleccion(item, i)').elevation-0 {{item.nombre}}
                v-flex(xs1 v-if='categorias.length > 0' style='display: flex;justify-content: center;align-items: center;z-index: 10;').pr
                  v-btn(style='height: 40px;width: 40px;min-width: 40px;background-color: transparent;color: black;border-radius: 100%;' @click="SlideCarousel('next')").elevation-0
                    v-icon(color='black' style='font-size:22px;') keyboard_arrow_right
                //- v-btn(v-for='(item,index) in categorias' :id='"btnn"+index' @click="seleccion(item, index)").btnm.elevation-0 {{item.nombre}}
            v-flex(v-if='!mostrar' xs12 sm6 md3 v-for='item in resultados' style='text-align: center;display: flex;justify-content: center;align-items: center;margin-bottom: 20px;padding:15px;')
                v-card(style='width:100%;height: 100%;background:#faf9f8;border:1px solid #dededd;border-radius:0px;cursor: pointer;display: flex;').elevation-0
                  div(style='width: 100%;height: 100%;padding-bottom: 40px;cursor: pointer' @click='ir(item)')
                    div(style='width: 100%;height: 270px;background:#fafafa;position:relative')
                        img(:src='ruta + item.imagen[0].url' style='width: 100%;height: 100%;padding:20px;object-fit: contain;')
                        span(v-if='item.rebaja' style='position: absolute;z-index: 1000;right: 14px;top: 14px;font-size: 12px;') REBAJA
                    div(style='margin-top: 10px;text-align:center;')
                        span(style='font-size: 15px;opacity: 0.7;') {{item.nombre}}
                    div(v-if='mostrarS' style='text-align:center;')
                      span(style='font-size: 15px;opacity: 0.7;') Existencia: {{item.existencia}}
                    div(style='text-align:center;' v-if='mostrarP')
                        span(style='font-size: 16px;font-weight: bold;') ${{item.precio.monto}}
                        span(v-if='item.rebaja' style='font-size: 14px;padding-left: 8px;color: #a9a9a9;text-decoration: line-through;') ${{item.rebaja}}
            v-flex(v-if="loading === true", xs12 sm12 md12 style='margin-top:10px;margin-bottom:10px;')
              v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                v-progress-circular(indeterminate='', color='primary')
    div(v-if='mostrar' style='height: auto;display: flex;justify-content: center;align-items: center;margin-top:40px;')
      v-flex(xs12 style ='height: auto;background: white;display: flex;justify-content: center;flex-direction: column;align-items: center;margin-bottom:40px')
        img(src='../assets/Iconos/vector pregunta.png')
        span(style='opacity: 0.7;') No se consiguieron resultados en su busqueda.
    //===========================================================Modal Espera==============================================================================================
    v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
      v-container(style='max-width: 400px;background:#f1f2f1')
        div(style="margin-top: 20px;")
            v-flex(xs12 sm12)
              v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                v-flex(xs12)
                  v-card-title(style="padding: 7px;").justify-center
                    h4.black--text  Esperando Respuesta del Servidor.!!
                v-flex(xs12)
                    div(style='display: flex;justify-content: center;').text-xs-center
                      img(src='../assets/waiting.gif', with='200' ,height='150')
                v-flex(xs12)
                  v-card-title(style="padding: 7px;").justify-center
                    h5.black--text  Por favor espere...
    Footer(id='templt')
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Api from '@/services/methods'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { serverImg } from '@/services/environment'
export default {
  name: 'Buscar',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      categorias: [],
      mostrarP: false,
      dialog4: false,
      mostrarS: false,
      pantalla: screen.width,
      ruta: serverImg,
      selec: '',
      cargar: false,
      loading: false,
      mostrar: false,
      category: '0',
      skip: 0,
      search: '',
      resultados: []
    }
  },
  mounted () {
    this.scroll()
  },
  async created () {
    document.documentElement.scrollTop = 0
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb.co_cli) {
      this.mostrarP = true
    }
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb.stock) {
      this.mostrarS = this.$store.state.fercoWeb.stock
    }
    this.dialog4 = true
    await this.initialize()
    this.dialog4 = false
  },
  methods: {
    scroll () {
      window.onscroll = () => {
        if (this.resultados.length > 0) {
          const firt = parseInt((document.documentElement.scrollTop + window.innerHeight).toFixed(0))
          const end = document.documentElement.offsetHeight - document.getElementById('templt').offsetHeight
          const bottomOfWindow = firt >= end
          if (bottomOfWindow && !this.mostrar && !this.cargar) {
            this.cargar = true
            this.loading = true
            this.skip = this.skip + 20
            Api.get('productos/all?skip=' + this.skip + '&categoria=' + this.selec + '&search=' + this.search).then(res => {
              for (let i = 0; i < res.data.length; i++) {
                this.resultados.push(res.data[i])
              }
              this.loading = false
              this.cargar = false
            })
          }
        }
      }
    },
    teclado (item) {
      if (item.key === 'Enter') {
        this.enviar()
      }
    },
    SlideCarousel (value) {
      const carousel = this.$refs.productDetails
      const currentPage = carousel.currentPage
      const pageCount = carousel.pageCount
      if (value == 'prev') {
        currentPage != 0 ? carousel.goToPage(currentPage - 1) : carousel.goToPage(pageCount - 1)
      } else {
        currentPage < pageCount - 1 ? carousel.goToPage(currentPage + 1) : carousel.goToPage(0)
      }
    },
    async initialize () {
      this.selec = ''
      this.mostrar = false
      await Api.get('productos/all?skip=' + this.skip).then(res => {
        this.resultados = res.data
      })
      await Api.get('categorias/random').then(res => {
        this.categorias = []
        this.categorias.push({ nombre: 'Todas', _id: '0' })
        for (let i = 0; i < res.data.length; i++) {
          this.categorias.push(res.data[i])
        }
        this.$nextTick(() => {
          for (let i = 0; i < this.categorias.length; i++) {
            let bool = false
            for (let j = 0; j < this.resultados.length; j++) {
              if (this.categorias[i]._id === this.resultados[j].categoria._id) {
                bool = true
              }
            }
            if (!bool) {
              this.categorias.splice(i, 1)
              i = 0
            }
          }
          if (document.getElementById('btnn0')) {
            document.getElementById('btnn0').className = 'btnmA v-btn v-btn--contained theme--light v-size--default elevation-0'
          }
        })
      })
    },
    ir (item) {
      this.$router.push('/detalle/' + item._id)
    },
    seleccion (item, index) {
      this.mostrar = false
      this.search = ''
      for (let i = 0; i < this.categorias.length; i++) {
        document.getElementById('btnn' + i).className = 'v-btn v-btn--contained theme--light v-size--default elevation-0'
      }
      document.getElementById('btnn' + index).className = 'btnmA v-btn v-btn--contained theme--light v-size--default elevation-0'
      this.resultados = []
      this.selec = item._id
      this.skip = 0
      this.dialog4 = true
      Api.get('productos/all?skip=' + this.skip + '&categoria=' + this.selec).then(res => {
        this.resultados = res.data
        this.dialog4 = false
      })
    },
    enviar () {
      if (this.search) {
        this.skip = 0
        this.resultados = []
        this.mostrar = false
        this.dialog4 = true
        const info = {
          search: this.search,
          condicion: this.selec,
          skip: this.skip
        }
        Api.post('productos/search', info).then(res => {
          this.resultados = res.data
          if (this.resultados.length === 0) {
            this.mostrar = true
          }
          this.dialog4 = false
        }).catch(err => {
          this.dialog4 = false
          console.log(err)
          Swal.fire({
            title: 'Error de conexión.',
            text: 'Error al conectar con el servidor intente nuevamente.',
            icon: 'error'
          })
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/ingreso.scss";
</style>
