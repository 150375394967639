import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: {
    fercoWeb: [],
    carFerco: [],
    token: ''
  },
  mutations: {
    login (state, data) {
      state.fercoWeb = data.user
      state.token = data.token
    },
    logout (state) {
      state.fercoWeb = null
      state.carFerco = []
      state.token = ''
    },
    perfil (state, data) {
      state.fercoWeb = data
    },
    datos (state, data) {
      state.fercoWeb = data
    },
    image (state, data) {
      state.fercoWeb = data
    },
    carrito (state, articulo) {
      let cambio = false
      if (state.carFerco.length === 0) {
        state.carFerco.push(articulo)
      } else {
        for (let i = 0; i < state.carFerco.length; i++) {
          if (state.carFerco[i]._id === articulo._id) {
            state.carFerco[i].cant = state.carFerco[i].cant + articulo.cant
            cambio = true
          }
        }
        if (cambio === false) {
          state.carFerco.push(articulo)
        }
      }
    },
    modificarcarrito (state, data) {
      for (let i = 0; i < state.carFerco.length; i++) {
        if (state.carFerco[i]._id === data._id) {
          state.carFerco[i].cant = data.cant
        }
        if (state.carFerco[i].cant === 0) {
          state.carFerco.splice(i, 1)
        }
      }
    },
    sumar (state, articulo) {
      for (let i = 0; i < state.carFerco.length; i++) {
        if (state.carFerco[i]._id === articulo._id) {
          state.carFerco[i].cant = articulo.cant
        }
      }
    },
    carrito2 (state, articulo) {
      for (let i = 0; i < state.carFerco.length; i++) {
        if (state.carFerco[i]._id === articulo._id) {
          state.carFerco[i].cant = state.carFerco[i].cant + articulo.cant
        }
      }
    },
    eliminar (state, articulo) {
      for (let i = 0; i < state.carFerco.length; i++) {
        if (state.carFerco[i]._id === articulo._id) {
          state.carFerco.splice(i, 1)
        }
      }
    },
    vaciarcarrito (state) {
      state.carFerco = []
    }
  },
  actions: {
    login ({ commit }, data) {
      commit('login', data)
    },
    logout ({ commit }) {
      commit('logout')
    },
    modificarperfil ({ commit }, data) {
      commit('perfil', data)
    },
    obtdatos ({ commit }, data) {
      commit('datos', data)
    },
    image ({ commit }, data) {
      commit('image', data)
    },
    agregarcarrito ({ commit }, articulo) {
      commit('carrito', articulo)
    },
    modificarcarrito ({ commit }, data) {
      commit('modificarcarrito', data)
    },
    sumar ({ commit }, data) {
      commit('sumar', data)
    },
    agregarcarrito2 ({ commit }, articulo) {
      commit('carrito2', articulo)
    },
    vaciarcarrito ({ commit }) {
      commit('vaciarcarrito')
    },
    eliminar ({ commit }, data) {
      commit('eliminar', data)
    }
  }
})
