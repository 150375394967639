<template lang="pug">
  #Ingreso
    Header
    div(style='background-color: gainsboro;height: 60px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 style='display: flex;justify-content: flex-start;align-items: center;')
            a(style='font:size:14px;font-weight: bold;color: #FF6200;text-decoration: initial;' href='/') Inicio
            span(style='padding-left:10px;') /
            span(style='font:size:14px;padding-left:10px;font-weight: bold;') Restablecer cuenta
    div(style='height: auto;display: flex;justify-content: center;align-items: center;')
      v-flex(xs10)
        v-layout(wrap style='margin-top:70px;')
            v-flex(xs12 sm12 style='padding:5px;text-align:center;')
                span(style='color:#888888;font-size: 30px;') Restablecer contraseña de cuenta
            v-flex(xs12 sm12 style='display:flex;justify-content:center;align-items:center;padding:5px;text-align:center;flex-direction:column;')
                span(style='color:#888888;') Contraseña
                v-text-field(outlined placeholder='Contraseña' v-model='form.password' color='#878787' style='width:500px;margin-top:5px;' type='password')
            v-flex(xs12 sm12 style='display:flex;justify-content:center;align-items:center;padding:5px;text-align:center;flex-direction:column;')
                span(style='color:#888888;') Confirmar contraseña
                v-text-field(outlined placeholder='Confirmar contraseña' v-model='form.retryPass' color='#878787' style='width:500px;margin-top:5px;' type='password')
            v-flex(xs12 sm12 style='display:flex;justify-content:center;align-items:center;padding:5px;text-align:center;flex-direction:column;margin-bottom: 80px;')
              v-btn(style='background:#ff5700;color:white;border-radius:0px;height: 45px;' @click='enviar()').elevation-0 Restablecer contraseña
    Footer
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Api from '@/services/methods'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { mapState, mapActions } from 'vuex'
import { serverImg } from '@/services/environment'
export default {
  name: 'Restablecer',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      form: {
        _id: '',
        password: '',
        retryPass: ''
      },
      pantalla: screen.width,
      ruta: serverImg
    }
  },
  created () {
    this.form._id = this.$route.params.id
    this.initialize()
  },
  methods: {
    enviar () {
      if (this.form._id && this.form.password && this.form.retryPass) {
        if (this.form.password === this.form.retryPass) {
          Api.put('clientesWeb/web', this.form)
            .then(res => {
              Swal.fire({
                title: '¡Felicidades!',
                text: 'Su contraseña fue cambiada con éxito, ya puede ingresar a nuestra plataforma.',
                icon: 'success'
              }).then(res => {
                this.$router.push('/ingreso')
              })
            })
            .catch(error => {
              Swal.fire(
                'Oops...',
                'Error de conexión, intente nuevamente o contacte con el administrador.',
                'error'
              )
              console.log(error)
            })
        } else {
          this.showAlert2()
        }
      } else {
        this.showAlert()
      }
    },
    showAlert () {
      Swal.fire({
        title: 'Error encontrado.',
        text: 'Estimado cliente, debe ingresar ambas contraseñas para continuar.',
        icon: 'error'
      })
    },
    showAlert2 () {
      Swal.fire({
        title: 'Error encontrado.',
        text: 'Estimado cliente, las contraseñas ingresadas no coinciden.',
        icon: 'error'
      })
    },
    initialize () {
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/ingreso.scss";
</style>
