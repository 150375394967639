<template lang="pug">
  #Productos
    Header
    div(style='background-color: gainsboro;height: 60px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 style='display: flex;justify-content: flex-start;align-items: center;')
            a(style='font:size:14px;font-weight: bold;color: #FF6200;text-decoration: initial;' href='/') Inicio
            span(style='padding-left:10px;') /
            span(style='font:size:14px;padding-left:10px;font-weight: bold;') Descripción de producto
    div(style='background-color: #FF6200;height: 150px;display: flex;justify-content: center;align-items: center;')
      v-flex(xs12)
        v-layout(wrap)
          v-flex(xs12 v-if='policeDepart')
            carousel(:perPageCustom="[[320, 1],[480, 2], [768, 4], [1160, 8]]")
              slide(v-for='(item,i) in arraySke' style='padding-left: 5px;padding-right: 5px;')
                div.carouselMarkSke
                  v-skeleton-loader.mx-auto#ld6(style='width:100%;' type='image')
          v-flex(xs12 v-if='departamentos && departamentos.length > 0')
            carousel(:perPageCustom="[[320, 1],[480, 2], [768, 4], [1160, 8]]")
              slide(v-for='(item,i) in departamentos' style='padding-left: 5px;padding-right: 5px;text-align:center;').centrarAll
                div(@click='obtenerDepart(item.co_lin)' style='cursor:pointer;').carouselMarkSke
                  img(:src='ruta + item.imagen' style='height:50px;width:auto;object-fit:contain;')
                  span(style='color:white;font-size:11px;margin-top:20px;') {{item.lin_des}}
    div(style='display:flex;justify-content:center;margin-top:60px;')
      v-flex(xs11)
        v-layout(wrap)
          v-flex(xs12 sm12 md3)
            div(v-if='policeCat' style='margin-bottom:40px;')
              v-skeleton-loader.mx-auto#ld7(style='width:100%;' type='image')
            div(v-if='categorias && categorias.length > 0' style='margin-bottom:40px;')
              v-card(color='#eaeaea' style="border-radius: 0px;padding-left: 20px;").elevation-0
                v-card-title
                  h4.black--text Categorías
                v-card-text
                  v-radio-group#rd(v-model='seleccionCatId' column='')
                    v-radio(v-for='item in categorias.slice(0, 10)' :label='item.subl_des' :value='item._id')
                  div(style='text-align:center;')
                    h3(style='color:#757575;cursor:pointer' @click='abrirModal("cat")') Ver más

            div(v-if='policeSub' style='margin-bottom:40px;')
              v-skeleton-loader.mx-auto#ld7(style='width:100%;' type='image')
            div(v-if='subcategorias && subcategorias.length > 0' style='margin-bottom:40px;')
              v-card(color='#eaeaea' style="border-radius: 0px;padding-left: 20px;").elevation-0
                v-card-title
                  h4.black--text Subcategorías
                v-card-text
                  v-radio-group#rd(v-model='seleccionSub' column='')
                    v-radio(v-for='item in subcategorias.slice(0, 10)' :label='item.des_color' :value='item.co_color')
                  div(style='text-align:center;')
                    h3(style='color:#757575;cursor:pointer' @click='abrirModal("subcat")') Ver más

            div(v-if='policeMarc' style='margin-bottom:40px;')
              v-skeleton-loader.mx-auto#ld7(style='width:100%;' type='image')
            div(v-if='marcas && marcas.length > 0' style='margin-bottom:40px;')
              v-card(color='#eaeaea' style="border-radius: 0px;padding-left: 20px;").elevation-0
                v-card-title
                  h4.black--text Marcas
                v-card-text
                  v-radio-group#rd(v-model='seleccionMarc' column='')
                    v-radio(v-for='item in marcas.slice(0, 10)' :label='item.cat_des' :value='item.co_cat')
                  div(style='text-align:center;')
                    h3(style='color:#757575;cursor:pointer' @click='abrirModal("marc")') Ver más
          v-flex(xs12 sm12 md1)
          v-flex(xs12 sm12 md8)
            div(v-if='seleccionDep || seleccionCat || seleccionSub || seleccionMarc' style='width:100%;height:auto;background:#0F4DFF;display:flex;align-items:center;padding: 0px 20px 0px 20px;')
              v-layout(wrap='' style='margin-bottom:20px;')
                v-flex(xs6 sm4 md3 v-if='seleccionDep' style='padding-top:20px;')
                  div(class='seleccton')
                    div(style='white-space: nowrap;text-overflow: ellipsis;overflow: hidden;')
                      span(style='color:#0F4DFF;text-align: center;') {{obtenerNombreDep(seleccionDep)}}
                    img(@click='limpiarDep()' src='@/assets/imgFerco/x rojo.png' style='width:11px;height:11px;object-fit:contain;margin-left:10px;cursor:pointer;')
                v-flex(xs6 sm4 md3 v-if='seleccionCat' style='padding-top:20px;')
                  div(class='seleccton')
                    div(style='white-space: nowrap;text-overflow: ellipsis;overflow: hidden;')
                      span(style='color:#0F4DFF;text-align: center;') {{obtenerNombreCat(seleccionCatId)}}
                    img(@click='limpiarCat()' src='@/assets/imgFerco/x rojo.png' style='width:11px;height:11px;object-fit:contain;margin-left:10px;cursor:pointer;')
                v-flex(xs6 sm4 md3 v-if='seleccionSub' style='padding-top:20px;')
                  div(class='seleccton')
                    div(style='white-space: nowrap;text-overflow: ellipsis;overflow: hidden;')
                      span(style='color:#0F4DFF;text-align: center;') {{obtenerNombreSub(seleccionSub)}}
                    img(@click='limpiarSub()' src='@/assets/imgFerco/x rojo.png' style='width:11px;height:11px;object-fit:contain;margin-left:10px;cursor:pointer;')
                v-flex(xs6 sm4 md3 v-if='seleccionMarc' style='padding-top:20px;')
                  div(class='seleccton')
                    div(style='white-space: nowrap;text-overflow: ellipsis;overflow: hidden;')
                      span(style='color:#0F4DFF;text-align: center;') {{obtenerNombreMarc(seleccionMarc)}}
                    img(@click='limpiarMarc()' src='@/assets/imgFerco/x rojo.png' style='width:11px;height:11px;object-fit:contain;margin-left:10px;cursor:pointer;')
                //- v-flex(xs12 style='text-align: center;padding-top: 10px;')
                //-   span(style='font-size:15px;font-weight: bold;').white--text {{cantidad}}
                //-   span(style='padding-left:10px;font-size:15px;').white--text productos encontrados
            div
              v-flex(xs12, sm12, md12)
                v-layout(v-if='policeProd' wrap style='margin-top:20px;')
                  v-flex(xs12='', md4='', sm12='', v-for='(item, index) in arrayPro' style='padding:10px;margin-bottom:40px;')
                    v-skeleton-loader.mx-auto#ld7(style='width:100%;' type='image')
                v-layout(v-if='listado && listado.length > 0' wrap style='margin-top:20px;')
                  v-flex(xs12='', md4='', sm12='', v-for='(item, index) in listado' style='padding:10px;margin-bottom:40px;')
                    template
                      a(@click='ir(item._id)').my-1
                        v-card(class='cardPro' style='height:100%;').mx-auto.marg.elevation-0
                          div(style='width: 100%;height: 270px;position:relative;display:flex;justify-content:center;align-items:center;')
                            img(v-if='item.images && item.images[0]' :src='ruta + item.images[0].url' style='width: 200px;height: 200px;object-fit: contain;')
                            img(v-else src='@/assets/imgFerco/image.png' style='width: 200px;height: 200px;object-fit: contain;')
                          div(style='display:flex;justify-content:center;')
                            div(style='width:80%;height:1px;background:lightgrey;')
                          div(style='margin-top: 10px;display:flex;flex-direction:column;')
                            span(style='font-size: 15px;font-weight: 800;color: grey;') {{item.nombre}}
                            span(style='font-size: 14px;font-weight: 500;color: grey;margin-top: 5px;') {{item.marca}}
                          div(v-if='mostrarS')
                            span(v-if='item.existencia && item.existencia > 0' style='font-size: 14px;font-weight: 500;color: grey;margin-top: 5px;') Existencia: {{item.existencia}}
                            span(v-else style='font-size: 14px;font-weight: 500;color: white;margin-top: 5px;background: red;padding: 1px 10px 1px 10px;border-radius: 40px;') Sin stock
                          div( v-if='mostrarP' style='padding-bottom:20px;')
                              span(style='font-size: 16px;font-weight: bold;color:red;') ${{item.precio}}
            div
              v-pagination(v-model='pagination.page' :length='listar()' color='#ff5700')
    Search(v-if='dialogSearch' :modal="dialogSearch" :tipo='tipoSearch' :resultados='datosSearch' @action="actionSearch" @close="close")
    Footer
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Search from '@/components/Search.vue'
import Api from '@/services/methods'
import { EventBus } from '../Events.js'
import { serverImg } from '@/services/environment'
export default {
  name: 'Productos',
  components: {
    Header,
    Footer,
    Search
  },
  data () {
    return {
      arraySke: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
      arrayPro: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      dialogSearch: false,
      tipoSearch: '',
      datosSearch: [],
      policeDepart: true,
      policeCat: true,
      policeSub: true,
      policeMarc: true,
      policeProd: true,
      departamentos: [],
      categorias: [],
      subcategorias: [],
      marcas: [],
      linea: '',
      seleccionCat: '',
      seleccionCatId: '',
      seleccionCatRes: '',
      seleccionSub: '',
      seleccionSubRes: '',
      seleccionMarc: '',
      seleccionMarcRes: '',
      seleccionDep: '',
      consulta: false,
      police2: false,
      policeList: false,
      pagination: {
        page: 1
      },
      maximo: 9,
      listado: [],
      productos: [],
      ruta: serverImg,
      page: 1,
      mostrarP: false,
      mostrarS: false,
      cantidad: 0,
      search: '',
      switch: false,

      catalogo: '',
      skip: 0,
      car: [],
      settings: []
    }
  },
  watch: {
    'pagination.page': function (val) {
      if (!this.policeList) {
        this.skip = (val - 1) * 9
        this.listado = []
        this.productos = []
        this.policeProd = true
        this.obtenerProductos()
      } else this.policeList = false
    },
    seleccionCatId (val) {
      if (val && !this.switch) {
        if (this.consulta) {
          if (!this.police2) {
            this.$swal.fire({
              position: 'top-end',
              icon: 'info',
              title: 'Consulta en proceso, por favor espere...',
              showConfirmButton: false,
              timer: 1500
            })
            if (!this.seleccionCatRes) {
              this.$nextTick(() => {
                this.seleccionCat = ''
              })
            } else {
              this.$nextTick(() => {
                this.police2 = true
                this.seleccionCat = this.seleccionCatRes
              })
            }
          } else this.police2 = false
        } else {
          for (let i = 0; i < this.categorias.length; i++) {
            const el = this.categorias[i]
            if (el._id.trim() == val.trim()) {
              this.seleccionCat = el.co_subl
              this.linea = el.co_lin
              this.seleccionCatRes = el.co_subl
              this.seleccionDep = el.co_lin
            }
          }
          this.skip = 0
          this.policeList = true
          this.pagination.page = 1
          this.productos = []
          this.cantidad = 0
          this.listado = []
          this.search = ''
          EventBus.$emit('limpiarSearch')
          this.policeProd = true
          this.obtenerProductos()
        }
      } else if (this.switch) this.switch = false
    },
    seleccionSub (val) {
      if (val) {
        if (this.consulta) {
          if (!this.police2) {
            this.$swal.fire({
              position: 'top-end',
              icon: 'info',
              title: 'Consulta en proceso, por favor espere...',
              showConfirmButton: false,
              timer: 1500
            })
            if (!this.seleccionSubRes) {
              this.$nextTick(() => {
                this.seleccionCat = ''
              })
            } else {
              this.$nextTick(() => {
                this.police2 = true
                this.seleccionSub = this.seleccionSubRes
              })
            }
          } else this.police2 = false
        } else {
          this.seleccionSubRes = val
          this.skip = 0
          this.productos = []
          this.cantidad = 0
          this.listado = []
          this.policeList = true
          this.pagination.page = 1
          this.policeProd = true
          this.search = ''
          EventBus.$emit('limpiarSearch')
          this.obtenerProductos()
        }
      }
    },
    seleccionMarc (val) {
      if (val && !this.switch) {
        if (this.consulta) {
          if (!this.police2) {
            this.$swal.fire({
              position: 'top-end',
              icon: 'info',
              title: 'Consulta en proceso, por favor espere...',
              showConfirmButton: false,
              timer: 1500
            })
            if (!this.seleccionMarcRes) {
              this.$nextTick(() => {
                this.seleccionCat = ''
              })
            } else {
              this.$nextTick(() => {
                this.police2 = true
                this.seleccionMarc = this.seleccionMarcRes
              })
            }
          } else this.police2 = false
        } else {
          this.seleccionMarcRes = val
          this.skip = 0
          this.productos = []
          this.policeList = true
          this.pagination.page = 1
          this.cantidad = 0
          this.listado = []
          this.policeProd = true
          this.search = ''
          EventBus.$emit('limpiarSearch')
          this.obtenerProductos()
        }
      } else if (this.switch) this.switch = false
    }
  },
  mounted () {
    EventBus.$on('buscarProducto', data => {
      this.search = data
      this.seleccionDep = ''
      this.skip = 0
      this.productos = []
      this.cantidad = 0
      this.listado = []
      this.policeProd = true
      this.pagination.page = 1
      this.switch = true
      this.seleccionDep = ''
      this.seleccionCat = ''
      this.seleccionSub = ''
      this.seleccionMarc = ''
      this.linea = ''
      document.documentElement.scrollTop = 0
      this.obtenerProductos()
    })
    EventBus.$on('buscarCategoria', data => {
      this.search = ''
      document.documentElement.scrollTop = 0
      this.seleccionCatId = data._id
      this.linea = data.co_lin.trim()
      this.seleccionCat = data.co_subl.trim()
      this.seleccionCatRes = data.co_subl.trim()
      this.seleccionDep = data.co_lin.trim()
    })
  },
  async created () {
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb.co_cli) {
      this.mostrarP = true
    }
    if (this.$store.state.fercoWeb && this.$store.state.fercoWeb.stock) {
      this.mostrarS = this.$store.state.fercoWeb.stock
    }
    if (this.$store.state.carFerco !== null) {
      this.car = this.$store.state.carFerco
    }
    if (this.$route.params && this.$route.params.value) this.search = this.$route.params.value
    if (this.$route.params && this.$route.params.depart) {
      document.documentElement.scrollTop = 0
      this.seleccionDep = this.$route.params.depart.co_lin
    }
    if (this.$route.params && this.$route.params.marca) {
      document.documentElement.scrollTop = 0
      this.switch = true
      this.seleccionMarc = this.$route.params.marca.co_cat
    }
    if (this.$route.params && this.$route.params.categoria) {
      document.documentElement.scrollTop = 0
      this.switch = true
      this.seleccionCatId = this.$route.params.categoria._id
      this.linea = this.$route.params.categoria.co_lin.trim()
      this.seleccionCat = this.$route.params.categoria.co_subl.trim()
      this.seleccionCatRes = this.$route.params.categoria.co_subl.trim()
      this.seleccionDep = this.$route.params.categoria.co_lin.trim()
    }
    await this.initialize()
  },
  methods: {
    abrirModal (item) {
      this.tipoSearch = item
      this.datosSearch = []
      if (item == 'cat') {
        for (let i = 0; i < this.categorias.length; i++) {
          const el = this.categorias[i]
          this.datosSearch.push(el)
        }
      }
      if (item == 'subcat') {
        for (let i = 0; i < this.subcategorias.length; i++) {
          const el = this.subcategorias[i]
          this.datosSearch.push(el)
        }
      }
      if (item == 'marc') {
        for (let i = 0; i < this.marcas.length; i++) {
          const el = this.marcas[i]
          this.datosSearch.push(el)
        }
      }
      this.dialogSearch = true
    },
    actionSearch (item) {
      if (this.tipoSearch == 'cat') this.seleccionCatId = item._id
      if (this.tipoSearch == 'subcat') this.seleccionSub = item.co_color
      if (this.tipoSearch == 'marc') this.seleccionMarc = item.co_cat
      this.tipoSearch = ''
      this.datosSearch = []
      this.dialogSearch = false
    },
    close () {
      this.dialogSearch = false
      this.tipoSearch = ''
      this.datosSearch = []
    },
    obtenerNombreDep (item) {
      let nomb = ''
      this.departamentos.forEach(el => {
        if (el.co_lin.trim() == item.trim()) nomb = el.lin_des
      })
      return nomb
    },
    obtenerNombreCat (item) {
      let nomb = ''
      this.categorias.forEach(el => {
        if (el._id.trim() == item.trim()) nomb = el.subl_des
      })
      return nomb
    },
    obtenerNombreSub (item) {
      let nomb = ''
      this.subcategorias.forEach(el => {
        if (el.co_color.trim() == item.trim()) nomb = el.des_color
      })
      return nomb
    },
    obtenerNombreMarc (item) {
      let nomb = ''
      this.marcas.forEach(el => {
        if (el.co_cat.trim() == item.trim()) nomb = el.cat_des
      })
      return nomb
    },
    obtenerDepart (item) {
      if (this.consulta) {
        this.$swal.fire({
          position: 'top-end',
          icon: 'info',
          title: 'Consulta en proceso, por favor espere...',
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        this.seleccionDep = item
        this.seleccionCat = ''
        this.linea = ''
        this.skip = 0
        this.productos = []
        this.cantidad = 0
        this.policeList = true
        this.pagination.page = 1
        this.listado = []
        this.policeProd = true
        this.search = ''
        EventBus.$emit('limpiarSearch')
        this.obtenerProductos()
      }
    },
    limpiarDep () {
      this.seleccionDep = ''
      this.skip = 0
      this.productos = []
      this.cantidad = 0
      this.listado = []
      this.policeProd = true
      this.search = ''
      EventBus.$emit('limpiarSearch')
      this.obtenerProductos()
    },
    limpiarCat () {
      this.linea = ''
      this.seleccionDep = ''
      this.seleccionCatId = ''
      this.seleccionCat = ''
      this.skip = 0
      this.productos = []
      this.cantidad = 0
      this.listado = []
      this.policeProd = true
      this.search = ''
      EventBus.$emit('limpiarSearch')
      this.obtenerProductos()
    },
    limpiarSub () {
      this.seleccionSub = ''
      this.skip = 0
      this.productos = []
      this.cantidad = 0
      this.listado = []
      this.policeProd = true
      this.search = ''
      EventBus.$emit('limpiarSearch')
      this.obtenerProductos()
    },
    limpiarMarc () {
      this.seleccionMarc = ''
      this.skip = 0
      this.productos = []
      this.cantidad = 0
      this.listado = []
      this.policeProd = true
      this.search = ''
      EventBus.$emit('limpiarSearch')
      this.obtenerProductos()
    },
    imagenes (item, index) {
      this.$nextTick(() => {
        const rut = this.ruta
        if (document.getElementById('imgg' + index)) {
          var holder = null
          var i = 1
          $('#imgg' + index).hover(function () {
            if (item[1] && item[1].url) {
              holder = setInterval(switchImages, 1000)
              // $(this).attr('src', rut + item[1].url)
            }
          }, function () {
            $(this).attr('src', rut + item[0].url)
            clearInterval(holder)
          })
          function switchImages () {
            if (item[i]) {
              $('#imgg' + index).attr('src', rut + item[i].url)
              i++
            } else {
              i = 0
            }
          }
        }
      })
    },
    ir (item) {
      this.$router.push('/detalle/' + item)
    },
    listar () {
      const val = this.cantidad / this.maximo
      if (val % 1 === 0) {
        return this.cantidad / this.maximo
      } else {
        const num = Math.trunc(this.cantidad / this.maximo)
        return num + 1
      }
    },
    obtenerProductos () {
      this.consulta = true
      const cli = (this.$store.state.fercoWeb && this.$store.state.fercoWeb.co_precio) ? this.$store.state.fercoWeb.co_precio.trim() : null
      Api.get(
        'productosCom/randomWeb?skip=' + this.skip +
        '&departamento=' + this.seleccionDep +
        '&categoria=' + this.seleccionCat +
        '&linea=' + this.linea +
        '&subcategoria=' + this.seleccionSub +
        '&marcas=' + this.seleccionMarc +
        '&codigo=' + cli +
        '&search=' + this.search
      ).then(res => {
        this.consulta = false
        this.$nextTick(() => {
          this.productos = res.data.resultados
          this.cantidad = res.data.cantidad
          for (let i = 0; i < this.productos.length; i++) {
            this.listado.push({
              _id: this.productos[i]._id,
              nombre: this.productos[i].art_des,
              marca: this.productos[i].marcaN,
              existencia: this.productos[i].stock,
              images: this.productos[i].imagen[0],
              precio: this.productos[i].precio,
              cant: 0
            })
          }
          this.policeList = false
          this.policeProd = false
        })
      }).catch(err => {
        console.log(err)
        this.consulta = false
      })
    },
    initialize () {
      this.listado = []
      this.skip = 0
      this.consulta = false
      Api.get('departamentos/withImg').then(res => {
        this.departamentos = res.data
        this.policeDepart = false
      })
      Api.get('categoriaWeb/limit').then(res => {
        this.categorias = res.data
        this.policeCat = false
      })
      Api.get('subcategoria/limit').then(res => {
        this.subcategorias = res.data
        this.policeSub = false
      })
      Api.get('marcas/limit').then(res => {
        this.marcas = res.data
        this.policeMarc = false
      })
      this.obtenerProductos()
      // Api.get('settings/catalogo2').then(res => {
      //   this.catalogo = res.data
      // })
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/productos.scss";
.VueCarousel-inner {
    flex-basis: 160px !important;
}
</style>
